import { defineStore } from 'pinia'
import { useUserStore } from './user'

import axios from 'axios'


export const useOrderStore = defineStore('order', {
    state: () => {
        return {
            loading: false,
            orders: null,
            userStore: useUserStore(),
        }
    },

    getters: {
        hasOrders() {
            return this.orders !== null && this.orders.length > 0
        },

        numberOpenOrders() {
            return this.orders ? this.orders.filter(order => order.status === 'pending' || order.status === 'processing').length : 0
        }
    },

    actions: {
        async fetchOrders() {
            if (!this.userStore.isAuthenticated) {
                console.error('Cannot fetch orders: User is not authenticated')
                return
            }

            this.loading = true
            
            return axios.get(`${import.meta.env.VITE_API_URL}/wp-json/regiomarkt/v1/orders`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${this.userStore.token}`
                }
            }).then(response => {
                this.orders = response.data
                console.log(response)
                this.loading = false
            })
        },

        async cancelOrder(id) {
            if (!this.userStore.isAuthenticated) {
                console.error('Cannot cancel order: User is not authenticated')
                return
            }

            this.loading = true

            console.log("Cancelling order", id)

            return axios.post(`${import.meta.env.VITE_API_URL}/wp-json/regiomarkt/v1/orders/cancel`, {
                order_id: id
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${this.userStore.token}`
                }
            }).then(response => {
                console.log(response)
                this.setOrder(id, response.data)
                this.loading = false
            })
        },

        getOrder(id) {
            if (!this.orders)
                return null

            return this.orders.find(order => order.id == id)
        },

        getStatusLabel(status) {
            switch (status) {
                case 'pending':
                    return 'Ausstehend'
                case 'processing':
                    return 'In Bearbeitung'
                case 'completed':
                    return 'Abgeholt'
                case 'cancelled':
                    return 'Storniert'
                case 'refunded':
                    return 'Zurückerstattet'
                case 'failed':
                    return 'Fehlgeschlagen'
                default:
                    return 'Unbekannt'
            }
        },

        setOrder(id, order) {
            let index = this.orders.findIndex(order => order.id == id)
            if (index === -1) {
                this.orders.push(order)
            } else {
                this.orders[index] = order
            }
        }
    }
})