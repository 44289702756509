<script>
export default {
    
}
</script>

<template>
    <div class="q-px-md q-py-lg text-center container-sm">
        <img src="@/assets/animations/lost.gif" width="300" alt="Animation, die ein Blatt zeigt das den Weg sucht." />
        <h1 class="text-h1">Ups!</h1>
        <div class="text-h1">Wir haben den Weg verloren.</div>
        <p>Diese Seite ist leider nicht (mehr) auffindbar...</p>
        <QBtn color="accent" text-color="darkGrey" label="Zum Shop" to="/" class="q-mt-xl full-width" />
    </div>
</template>