<script>
import axios from 'axios';

export default {
    data() {
        return {
            producer: null
        }
    },

    mounted() {
        this.fetchProducer();
    },

    computed: {
        producerId() {
            return this.$route.params.producerId;
        }
    },

    methods: {
        async fetchProducer() {
            axios.get(`${import.meta.env.VITE_API_URL}/wp-json/regiomarkt/v1/producers/${this.producerId}`)
                .then(response => {
                    this.producer = response.data;
                })
                .catch(error => {
                    this.$q.notify({
                        color: 'negative',
                        icon: 'report_problem',
                        message: 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.',
                        position: 'top',
                        timeout: 3000
                    });
                    console.error('Error fetching producer:', error);
                })
        }
    }

}
</script>

<template>
    <div v-if="producer">
        <div class="q-px-md q-py-lg text-center">
            <div class="row align-center">
                <div class="col-shrink column justify-center">
                    <QBtn icon="arrow_back_ios" @click="this.$router.go(-1)" size="24px" flat color="accent" />
                </div>
                <div class="col-grow text-center">
                    <div class="text-h2">{{ producer.name }}</div>
                    <div class="text-center">{{ producer.address.zip }} {{ producer.address.city }}</div>
                </div>
            </div>
    
            <div class="q-pt-xl container-sm">
                {{ producer.description }}
            </div>
        </div>

        <div class="q-mt-md">
            <QImg :src="producer.image_url" alt="Producer Image" class="producer-image" />
        </div>

        <div class="q-px-md text-center container">
            <div class="q-mt-xl" v-html="producer.content"></div>
        </div>
    </div>
    <div class="q-pa-lg text-center" v-else>
        <QSpinner size="50px" class="q-mx-auto" />
    </div>
</template>

<style lang="scss" scoped>
    @use '@/sass/mixins';

    .producer-image {
        height: 300px;
        width: 100%;

        @include mixins.wave-mask;
    }
</style>