<script>
import { mapStores } from 'pinia';
import { useUserStore } from '@/stores/user';
import PickupPointInfo from '@/components/PickupPoint/PickupPointInfo.vue'

export default {
    data() {
        return {
            loading: false,
            stripe: null,
            succeeded: true,
            orderId: null,
        }
    },

    components: {
        PickupPointInfo,
    },

    mounted() {
        this.succeeded = this.$route.query.redirect_status === 'succeeded'
        if (!this.succeeded) {
            this.$router.push({ name: 'checkout', params: { step: 'payment' } })
            this.$q.notify({
                message: 'Bei der Bezahlung ist etwas schiefgegangen, bitte versuchen Sie es erneut.',
                color: 'negative',
                position: 'top',
                icon: 'error',
                timeout: 5000,
            })
        } else {
            this.orderId = this.$route.query.order_id
        }
    },

    computed: {
        ...mapStores(useUserStore),
    },
}
</script>

<template>
    <div>
        <div class="text-center q-px-md q-py-lg" v-if="succeeded">
            <img src="@/assets/animations/success.gif" width="215px" alt="Animation, die den Erfolg der Bestellung anzeigt." />
            <h2>Danke!</h2>
            <div>Mit Ihrer Bestellung unterstützen Sie Ihre <RouterLink to="/producers">lokalen Bauern!</RouterLink></div>
            <div class="q-mt-md">Bestellnummer: {{ orderId }}</div>
            <div class="q-mt-xl">
                <div class="text-h4 q-py-lg">Abholung</div>
                <PickupPointInfo stretched="md" :pickupPoint="userStore.pickupPoint" />
            </div>
            <QBtn class="full-width q-mt-xl" color="accent" text-color="black" to="/profile">Zum Kundenkonto</QBtn>
        </div>
    </div>
</template>