<script>
import { mapStores } from 'pinia';
import { useUserStore } from '@/stores/user';

export default {
    data() {
        return {

        }
    },

    emits: ['ok', 'hide'],

    computed: {
        ...mapStores(useUserStore),
    },
    
    methods: {
        show() {
            this.$refs.dialog.show()
        },

        hide() {
            this.$refs.dialog.hide()
        },

        onDialogHide() {
            this.$emit('hide') 
        },

        onOkClick() {
            this.$emit('ok')
            this.userStore.setCookieAcceptance('all')
            this.hide()
        },

        onCancelClick() {
            this.userStore.setCookieAcceptance('functional')
            this.hide()
        },
    }
}
</script>

<template>
    <QDialog ref="dialog" @hide="onDialogHide" persistent>
        <QCard class="q-dialog-plugin q-pa-lg container-sm">
            <div class="column text-center items-center">
                <img src="@/assets/austrian-cookie.svg" style="width:140px" class="q-px-auto" alt="Linzerauge" />
                <h3 class="text-h3">Die besten österreichischen Cookies!</h3>
                <p class="text-micro">Durch unsere mit Butter verfeinerten Cookies erfährst Du die Geschmeidigkeit unserer Website.</p>
                <p>
                    Wir setzen Cookies für verschiedenste Zwecke ein. Einige Cookies sind technisch notwendig (u.A. auch von Drittanbietern), andere hingegen erfordern Deine
                    Zustimmung. Cookies, die zur Analyse der Website und zur Verbesserung der Nutzererfahrung dienen, kannst Du mit den Schaltflächen akzeptieren oder ablehnen.
                </p>
                <p>
                    Du kannst Deine Einstellungen jederzeit ändern oder die Cookies im Impressum deaktivieren. Weitere Informationen findest Du in unserer
                    <a href="/imprint" target="_blank">Datenschutzerklärung</a>.
                </p>
                <div class="row items-center q-col-gutter-md full-width q-mt-md">
                    <div class="col-12 col-md-6 q-pa-sm">
                        <QBtn label="Alle ablehnen" color="accent" class="full-width" outline @click="onCancelClick" />
                    </div>
                    <div class="col-12 col-md-6 q-pa-sm">
                        <QBtn label="Alle akzeptieren" color="accent" class="full-width" outline @click="onOkClick" />
                    </div>
                </div>
            </div>
        </QCard>
    </QDialog>
</template>