<script>
import { mapStores } from 'pinia'
import { useUserStore } from '@/stores/user'
import { usePickupPointStore } from '@/stores/pickupPoint';

export default {
    props: {
        pickupPoint: Object,
        orderDate: {
            type: Object,
            default: null
        },
        shortAddress: Boolean,
        stretched: String,
        changable: Boolean,
        showDeadline: Boolean
    },

    computed: {
        ...mapStores(useUserStore, usePickupPointStore),
        pickupPointShortAddress() {
            if (this.pickupPoint) {
                const { title, acf } = this.pickupPoint;
                let infoStr = `${title.rendered}, ${acf.location.street_name} ${acf.location.street_number}, ${acf.location.post_code} ${acf.location.city}`

                if (infoStr.length > 30) {
                    return `${infoStr.substring(0, 27)}...`;
                } else {
                    return infoStr;
                }
            } else
                return null
        },

        pickupDate() {
            let date = ''
            if (this.orderDate) {
                date = this.pickupPointStore.nextPickupDate(this.pickupPoint.id, this.orderDate)
            } else {
                date = this.pickupPointStore.nextPickupDate(this.pickupPoint.id)
            }

            return date.toLocaleDateString('de-DE', { weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric' });
        },

        orderDeadline() {
            const nextPickupDate = this.pickupPointStore.nextPickupDate(this.pickupPoint.id)
            let deadline = new Date(nextPickupDate);
            deadline.setHours(deadline.getHours() - 36);

            return deadline
        },

        timeToDeadline() {
            let diff = this.pickupPointStore.nextOrderDeadline(this.userStore.pickupPointId) - this.userStore.time;
            let days = Math.round(diff / 1000 / 60 /60 / 24)
            let hours = Math.floor(diff / 1000 / 60 / 60);
            let minutes = Math.floor(diff / 1000 / 60 % 60);

            return days ? `${days} Tag${days > 1 ? 'e' : ''}` : `${hours}h ${minutes}m`
        
        }
    }
}
</script>
<template>
    <div class="bg-secondary">
        <div class="row bg-secondary q-pa-md text-micro pickup-info container-sm" v-if="pickupPoint" :class="stretched ? `stretched-${stretched}` : ''">
            <div class="col-7 q-pa-xs text-left">
                <div>Abholstation:</div>
                <div class="text-accentLight" v-if="shortAddress">{{ pickupPointShortAddress }}</div>
                <div class="text-accentLight" v-else>
                    <div>{{ pickupPoint.acf.location.name }}</div>
                    <div>{{ pickupPoint.acf.location.street_name }} {{ pickupPoint.acf.location.street_number }}</div>
                    <div>{{ pickupPoint.acf.location.post_code }} {{ pickupPoint.acf.location.city }}</div>
                </div>
                <div v-if="changable" class="text-accentLight text-underline">Ändern</div>
            </div>
            <div class="col-5 q-pa-xs text-right">
                <div class="text-white">{{ pickupDate }}</div>
                <div class="text-accentLight">{{ pickupPoint.acf.pickup_time.time_from.slice(0, 5) }} - {{ pickupPoint.acf.pickup_time.time_until.slice(0, 5) }}</div>
                <div v-if="showDeadline" class="text-accentLight">Bestellfrist {{ timeToDeadline }}</div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
    .stretched-md {
        margin: 0 -16px;
    }

    .stretched-lg {
        margin: 0 -24px;
    }
</style>