<script>
import { mapStores } from 'pinia'
import { useUserStore } from '@/stores/user.js'
import { usePickupPointStore } from '@/stores/pickupPoint.js'
import { useNavigationStore } from '@/stores/navigation'

import PickupPlaceSearch from './PickupPlaceSearch.vue'

import locationMarkerUrl from '@/assets/location_marker.svg'
import myLocationMarkerUrl from '@/assets/my_location_marker.svg'

export default {
    data() {
        return {
            mapView: false,
            loading: false,
            defaultMapCenter: { lat: 47.9942527, lng: 16.1960499 },
            selectedMapPoint: null,
            headerHeight: 82,
        }
    },

    props: {
        searchString: String,
        hideHeader: Boolean,
    },

    components: {
        PickupPlaceSearch
    },

    watch: {
        'pickupPointStore.showSelector' : { handler(newValue) {
            if (!newValue && this.selectedMapPoint) this.selectedMapPoint = null; // Close map selection window when closing selector
            if (newValue) {
                document.body.classList.add('no-scroll')
                window.scrollTo(0, 0)
            }
            else document.body.classList.remove('no-scroll')
        }},
        'pickupPointStore.loading' : { handler(newValue) {
            if (!newValue && this.loading) this.loading = false
        }},
    },

    computed: {
        ...mapStores(useUserStore, usePickupPointStore, useNavigationStore),
        locationMarkerUrl: () => locationMarkerUrl,
        myLocationMarkerUrl: () => myLocationMarkerUrl,

        mapCenter() {
            const lat = this.selectedMapPoint?.acf.location.lat ?? this.userStore.location?.coordinates.latitude ?? this.defaultMapCenter.lat
            const lng = this.selectedMapPoint?.acf.location.lng ?? this.userStore.location?.coordinates.longitude ?? this.defaultMapCenter.lng

            return {lat, lng}
        },

        pickupPoints() {
            let points = null

            if (this.userStore.location) {
                points = Array.from(this.pickupPointStore.pickupPoints).sort((a, b) => {
                    const distanceA = a.distance || 0;
                    const distanceB = b.distance || 0;
                    return distanceA - distanceB;
                })
            } else {
                points = this.pickupPointStore.pickupPoints.slice(0, 5)
            }

            return points
        },

    },

    methods: {
        selectMapPoint(point) {
            this.selectedMapPoint = point;
        },

        selectPickupPoint(point) {
            this.loading = true;
            this.pickupPointStore.changePickupPoint(point).then(() => {

                if (this.$route.path === '/start') {
                    this.$router.push('/')
                }
                
                document.body.classList.remove('no-scroll')
                this.pickupPointStore.showSelector = false
                this.loading = false
            });  
        },

        getDayOfWeek(day) {
            const weekdayMap = { 'sun': 'Sonntag', 'mon': 'Montag', 'tue': 'Dienstag', 'wed': 'Mittwoch', 'thu': 'Donnerstag', 'fri': 'Freitag', 'sat': 'Samstag' };
            return weekdayMap[day.toLowerCase()];
        },

        getHeaderHeight() {
            return document.querySelector('header.q-header').clientHeight
        },
    }
}
</script>

<template>
    <div
        class="absolute full-width pickup-point overflow-auto" 
        style="z-index: 999; top: 0; left: 0; max-height: 100vh;" 
        :style="{ 'padding-top': headerHeight + 'px', 'height': pickupPointStore.showSelector ? '100vh' : '0' }"
    >
        <QInnerLoading :showing="this.loading" class="z-top" />
        <div class="pickup-point-selector q-px-md q-py-lg container-sm" v-if="pickupPointStore.showSelector">
            <div class="column cursor-pointer" @click="pickupPointStore.toggleSelector()">
                <QIcon name="expand_less" size="34px" color="lightGrey" class="q-mx-auto" />
                <div class="text-h4 text-center text-lightGrey" style="margin-top: -8px;">Schließen</div>
            </div>
            <div class="column justify-between full-height no-wrap">
                <div>
                    <PickupPlaceSearch />
                    <div class="text-h4 text-center q-mt-lg">Wähle deine Abholstation:</div>
                    <QSeparator width="150px" class="q-mt-md dashed q-mx-auto" />
                </div>
                <div v-if="!mapView" class="flex-grow full-height">
                    <div v-if="!pickupPointStore.pickupPoints && pickupPointStore.loading">
                        <div v-for="i in 3" :key="i">
                            <div class="column q-py-lg">
                                <QSkeleton type="rect" width="160px" height="16px" />
                                <QSkeleton type="rect" width="210px" height="16px" class="q-mt-sm" />
                                <QSkeleton type="rect" width="80px" height="14px" class="q-mt-lg" />
                                <QSkeleton type="rect" width="120px" height="14px" class="q-mt-sm" />
                            </div>
                            <QSeparator color="tertiary" size="4px" class="q-my-md dashed" v-if="i != 3" />
                        </div>
                    </div>
                    <div v-for="(point, index) in pickupPoints" :key="index">
                        <div class="row q-py-md cursor-pointer" @click="selectPickupPoint(point)">
                            <div class="col-grow">
                                <div class="text-bold text-accent q-mb-sm">{{ point.title.rendered }}</div>
                                <div class="text-micro" v-if="point.distance">
                                    Entfernung: ca. {{ point.distance.toFixed(1) }} km
                                </div>
                                <div>{{ point.acf.location.street_name }} {{ point.acf.location.street_number }}, 
                                {{ point.acf.location.post_code }} {{ point.acf.location.city }}</div>
                                <div class="q-mt-md">
                                    <div class="text-micro">Abholung:</div>
                                    <div>{{ getDayOfWeek(point.acf.pickup_time.day) }}, {{ point.acf.pickup_time.time_from.slice(0, 5) }} - {{ point.acf.pickup_time.time_until.slice(0, 5) }}</div>
                                </div>
                            </div>
                            <div class="col-shrink column justify-center">
                                <QIcon name="arrow_forward_ios" size="24px" color="accent" />
                            </div>
                        </div>
                        <QSeparator color="tertiary" size="4px" class="q-my-md dashed" v-if="index != pickupPoints.length - 1" />
                    </div>
                </div>
                <div v-else class="flex-grow relative-position q-pt-lg">
                    <GMapMap
                        v-if="userStore.cookieAcceptance == 'all'"
                        :center="mapCenter"
                        :zoom="10"
                        map-type-id="roadmap"
                        style="width: 100%; height: 550px"
                        @click="selectedMapPoint = null"
                    >
                        <QInnerLoading :show="loading" />
                        <GMapMarker
                            v-for="point in this.pickupPointStore.pickupPoints"
                            :key="point.id"
                            :position="point.acf.location"
                            :icon='{
                                url: locationMarkerUrl,
                                scaledSize: {width: 48, height: 48},
                                labelOrigin: {x: 24, y: 48}
                            }'
                            @click="selectMapPoint(point)"
                        />
                        <GMapMarker
                            v-if="userStore.location"
                            :position="{lat: userStore.location.coordinates.latitude, lng: userStore.location.coordinates.longitude}"
                            :icon="{
                                url: myLocationMarkerUrl,
                                scaledSize: {width: 32, height: 32},
                                labelOrigin: {x: 16, y: 16}
                            }"
                        />
                    </GMapMap>
                    <div v-else class="bg-accentLight text-black text-center column justify-center q-pa-lg" style="width: 100%; height: 550px;">
                        <img src="@/assets/cookies.svg" style="width: 88px; height: 80px;" class="q-mx-auto" />
                        <div class="q-mt-lg">
                            Um die Karte anzuzeigen, brauchen wir Ihre Zustimmung für Cookies von Drittanbietern.
                        </div>
                        <QBtn label="Alle Cookies akzeptieren" color="tertiary" class="q-mt-md q-mx-auto" @click="userStore.setCookieAcceptance('all')" />
                        <QBtn label="Datenschutzerklärung" flat color="tertiary" class="q-mt-md q-mx-auto" />
                    </div>
                    <QSlideTransition>
                        <div v-if="selectedMapPoint" class="full-width bg-accentLight text-black absolute-bottom q-pa-lg">
                            <div class="text-h6 text-primary">{{ selectedMapPoint.title.rendered }}</div>
                            <div>{{ selectedMapPoint.acf.location.street_name }} {{ selectedMapPoint.acf.location.street_number }}, 
                            {{ selectedMapPoint.acf.location.post_code }} {{ selectedMapPoint.acf.location.city }}</div>
                            <div class="q-mt-md">
                                <div class="text-h6">Abholung:</div>
                                <div>{{ getDayOfWeek(selectedMapPoint.acf.pickup_time.day) }}, {{ selectedMapPoint.acf.pickup_time.time_from.slice(0, 5) }} - {{ selectedMapPoint.acf.pickup_time.time_until.slice(0, 5) }}</div>
                            </div>
                            <QBtn label="Abholstation wählen" color="tertiary" class="q-mt-md" :loading="loading" @click="selectPickupPoint(selectedMapPoint)" />
                        </div>
                    </QSlideTransition>
                </div>
                <div class="">
                    <div class="flex justify-center items-end q-py-lg">
                        <QBtn :label="mapView ? 'Listenansicht' : 'Kartenansicht'" color="accent" flat :icon="mapView ? 'menu' : 'location_on'" @click="mapView = !mapView" />
                    </div>
                </div>
            </div>
            <div class="q-my-lg text-center">
                <div class="text-h4">Neue Abholstation im Kopf?</div>
                <QSeparator width="150px" class="dashed q-my-md q-mx-auto" />
                <div class="">
                    Inspiration ist immer Willkommen! 
                    Werden deine Ideen umgesetzt bekommst du eine Gutschrift für deinen Einkauf.
                </div>
                <QBtn label="Idee senden" class="q-mt-xl q-mx-auto" color="accent" text-color="black" @click="navigationStore.openFeedbackDialog()" />
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .pickup-point {
        background: url('@/assets/background-gradient.svg');
        background-size: 100% 100%;
        transition: height 0.4s;
    }
</style>