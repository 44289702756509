<script>
import axios from 'axios'
import { mapStores } from 'pinia'
import { useUserStore } from '@/stores/user'
import { useProductStore } from '@/stores/product'
import { usePickupPointStore } from '@/stores/pickupPoint'

export default {
    data() {
        return {
            categoryExpanded: false,
            categories: [],
            producers: [],
            searchString: null,
            sortBy: null,
            producer: null,
        }
    },

    mounted() {
        this.fetchProducers();
        this.fetchCategories();
        this.searchString = this.productStore.filter.searchString;
        this.sortBy = this.productStore.filter.sortBy;
        this.producer = this.productStore.filter.producer;
    },

    computed: {
        ...mapStores(useUserStore, useProductStore, usePickupPointStore),
    },

    methods: {
        selectCategory(cat) {
            this.categoryExpanded = false;
            this.productStore.setFilter({ category: cat })
            this.searchString = null,
            this.sortBy = null,
            this.producer = null
        },

        async fetchProducers() {
            await this.pickupPointStore.initialized
            axios.get(`${import.meta.env.VITE_API_URL}/wp-json/regiomarkt/v1/producers?pickup_point_id=${this.userStore.pickupPoint.id}`)
                .then(response => {
                    this.producers = [
                        { id: -1, name: 'Alle' },
                        ...response.data.producers
                    ]
                })
                .catch(error => {
                    this.$q.notify({
                        color: 'negative',
                        icon: 'report_problem',
                        message: 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.',
                        position: 'top',
                        timeout: 3000
                    });
                    console.error('Error fetching producers:', error);
                })
        },

        fetchCategories() {

            axios.get(`${import.meta.env.VITE_API_URL}/wp-json/wp/v2/product_cat`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(response => {
                this.categories = response.data
                    .filter(category => category.slug !== 'uncategorized')
                    .map(category => {
                        category.name = category.name.replace(/&amp;/g, ' & ')
                        return category
                    })
                
            })
            .catch(error => {
                this.$q.notify({
                    color: 'negative',
                    icon: 'report_problem',
                    message: 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.',
                    position: 'top',
                    timeout: 3000
                });
                console.error('Error fetching categories:', error);
            });
        },
        
        setFilter(filter) {
            let trackSearch = this.searchString;

            setTimeout(() => {
                if (this.searchString === trackSearch) {
                    this.productStore.setFilter(filter);
                }
            }, 500);   
        }
    },

}

</script>

<template>
    <div class="row justify-center q-px-md q-px-md-lg">
        <div class="col-12 col-md-3 q-py-sm">
            <div class="flex justify-center q-px-md">
                <q-expansion-item
                    expand-separator
                    :label="this.productStore.filter.category.name"
                    v-model="categoryExpanded"
                    class="category-selector full-width text-center"
                    header-class="text-accent"
                    expand-icon-class="text-accent"
                    style="z-index: 10;"
                >
                    <q-item clickable v-ripple class="q-pa-md cursor-pointer justify-center" @click="selectCategory({id: -1, name: 'Alle Produkte'})">Alle Produkte</q-item>
                    <q-separator dark color="primary" />
                    <div v-for="(category, index) in categories" :key="index" @click="selectCategory(category)">
                        <q-item clickable v-ripple class="q-pa-md cursor-pointer justify-center">
                            <span>{{ category.name }}</span>
                        </q-item>
                        <q-separator v-if="index < categories.length - 1" dark color="primary" />
                    </div>
                </q-expansion-item>
            </div>
        </div>
        <div class="col-12 col-md-6 q-py-sm">
            <div class="q-px-md">
                <q-input 
                    outlined 
                    @keydown="setFilter({ ...this.productStore.filter, searchString: this.searchString })" 
                    @clear="productStore.setFilter({ ...this.productStore.filter, searchString: '' })"
                    v-model="searchString" 
                    label="Suche nach Produkten" 
                    class="q-mb-md full-width"
                    style="max-width: none;"
                    clearable
                    color="white"
                >
                    <template v-slot:prepend>
                        <q-icon name="search" @click="productStore.setFilter({ ...this.productStore.filter, searchString: this.searchString })" class="cursor-pointer" />
                    </template>
                </q-input>
            </div>
        </div>
        <div class="col-12 col-md-3 q-py-sm">
            <div class="row q-mb-lg">
                <div class="col-6 q-px-md">
                    <QSelect
                        v-model="producer"
                        :options="producers"
                        label="Produzent"
                        map-options
                        option-value="id"
                        option-label="name"
                        @update:model-value="productStore.setFilter({ ...this.productStore.filter, producer: this.producer })"
                        color="accent"
                        input-class="text-accent"
                        borderless
                        hide-dropdown-icon
                    >
                        <template v-slot:prepend>
                            <QIcon name="tune" />
                        </template>
                        <template v-slot:no-option>
                            <q-item>
                                <q-item-section class="text-grey">
                                Keine Ergebnisse
                                </q-item-section>
                            </q-item>
                        </template>
                    </QSelect>
                </div>
                <div class="col-6 justify-end q-px-md">
                    <QSelect
                        :model-value="productStore.sort.name"
                        :options="productStore.sortOptions"
                        map-options
                        option-label="name"
                        label="Sortieren nach"
                        @update:model-value="productStore.setSort"
                        color="accent"
                        input-class="text-accent"
                        borderless
                        hide-dropdown-icon
                    >
                        <template v-slot:append>
                            <QIcon name="sort" />
                        </template>
                    </QSelect>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
    @media (max-width: var(--q-breakpoint-md)) {
        .category-selector {
            position: absolute;
        }
    }
</style>