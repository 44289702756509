<script>
import { mapStores } from 'pinia'
import { useCartStore } from '@/stores/cart'
import { useUserStore } from '@/stores/user';

export default {
    data() {
        return {
            loading: false,
            showAddCoupon: false,
            appliedCoupons: [],
            coupon: ''
        }
    },

    props: {
        totals: Object,
        coupons: Object,
        hideAddCoupon: Boolean,
        fees: {
            type: Object,
            default: () => ({})
        },
    },

    watch: {
        coupons: {
            handler(coupons) {
                if (!coupons) {
                    return
                }

                this.appliedCoupons = coupons.map(coupon => coupon.coupon)
            },
            immediate: true
        }
    },

    mounted() {
        this.cartStore.initialized.then(() => {
            this.appliedCoupons = this.cartStore.coupons?.map(coupon => coupon.coupon)
        })
    },

    computed: {
        ...mapStores(useCartStore, useUserStore),

        discountsTotal() {
            return Number(this.totals.discount_tax) + Number(this.totals.discount_total)
        }
    },

    methods: {
        getSubtotalWithTax() {
            return this.cartStore.getFormattedPrice(String(Number(this.totals.subtotal) + Number(this.totals.subtotal_tax)));
        },

        async addCoupon() {
            if (!this.coupon || this.isCouponApplied(this.coupon)) {
                return
            }

            this.loading = 'addCoupon'

            this.cartStore.pushCoupon(this.coupon).then(response => {
                console.log(response)
                this.appliedCoupons.push(this.coupon)
                this.coupon = ''
                this.showAddCoupon = false
                this.cartStore.fetchCart()
            }).finally(() => {
                this.loading = false
            })
        },

        removeCoupon(coupon) {
            this.loading = 'removeCoupon'

            this.cartStore.removeCoupon(coupon).then(response => {
                this.appliedCoupons = this.appliedCoupons.filter(appliedCoupon => appliedCoupon !== coupon)
                console.log(response)
                this.cartStore.fetchCart()
            }).finally(() => {
                this.loading = false
            })
        },

        isCouponApplied(coupon) {
            return this.coupons.map(coupon => coupon.coupon).includes(coupon)
        }
    }
}
</script>

<template>
    <div>
        <div v-if="! userStore.isAuthenticated">
            <div class="text-micro text-bold q-py-lg">Gutscheincodes kannst Du im letzten Schritt hinzufügen.</div>
        </div>
        <div v-else-if="!hideAddCoupon" class="q-py-sm">
            <QBtn flat color="accent" icon="sym_r_add" @click="showAddCoupon = true">Gutscheincode hinzufügen</QBtn>
            <QSlideTransition>
                <div v-if="showAddCoupon" class="row q-py-md q-col-gutter-md items-center full-width">
                    <div class="col col-grow">
                        <QInput outlined v-model="coupon" label="Gutscheincode" class="full-width" @keydown.enter="addCoupon" />
                    </div>
                    <div class="col">
                        <QBtn flat color="accent" label="Einlösen" @click="addCoupon" :loading="loading === 'addCoupon'" />
                    </div>
                </div>
            </QSlideTransition>
            <div class="q-py-lg">
                <QChip 
                    v-for="coupon in appliedCoupons" 
                    :key="coupon" 
                    removable 
                    color="accentLight" 
                    text-color="darkGrey"
                    class="text-bold"
                    @remove="removeCoupon(coupon)"    
                >
                    <QInnerLoading v-if="loading === 'removeCoupon'" class="z-top" />
                    {{ coupon.toUpperCase() }}
                    <QSpinner v-if="loading === 'removeCoupon'" class="q-ml-md" />
                </QChip>
            </div>
        </div>
        <div class="row q-py-sm" v-if="!totals.shipping_total === 0">
            <div class="col-6">Versand</div>
            <div class="col-6 text-bold text-right">{{ cartStore.getFormattedPrice(totals.shipping_total) }}</div>
        </div>
        <div class="row q.py.sm" v-if="Object.keys(fees).length !== 0">
            <div class="col-6">Warenwert (inkl. USt.)</div>
            <div class="col-6 text-bold text-right">{{ this.getSubtotalWithTax() }}</div>
        </div>
        <div class="row q-py-sm" v-for="(fee, index) in fees" :key="index">
            <div class="col-6">{{ fee.name }}</div>
            <div class="col-6 text-bold text-right">{{ cartStore.getFormattedPrice(fee.fee) }}</div>
        </div>
        <div class="row q-py-sm" v-if="discountsTotal">
            <div class="col-6">Gutscheine</div>
            <div class="col-6 text-bold text-right">-{{ cartStore.getFormattedPrice(discountsTotal) }}</div>
        </div>
        <QSeparator color="tertiary" class="q-my-md" size="2px" />
        <div class="row q-mb-lg">
            <div class="col-6 text-bold">Gesamtbetrag</div>
            <div class="col-6 text-bold text-right">{{ cartStore.getFormattedPrice(totals.total) }}</div>
        </div>
        <div class="q-py-sm"></div>
    </div>
</template>