<script>
import { mapStores } from 'pinia'
import { useOrderStore } from '@/stores/order';
import { useCartStore } from '@/stores/cart'
import { usePickupPointStore } from '@/stores/pickupPoint';

export default {
    data() {
        return {
            loading: false
        }
    },

    computed: {
        ...mapStores(useCartStore, useOrderStore, usePickupPointStore)
    },

    methods: {
        getPickupPoint(order) {
            return this.pickupPointStore.getPickupPoint(order.pickupPointId)
        },

        getNextPickupDate(order) {
            const orderDate = this.pickupPointStore.parseDate(order.date, 'dd.mm.yyyy')
            orderDate.setHours(order.time.split(":")[0], order.time.split(":")[1], order.time.split(":")[2])
            return this.pickupPointStore.nextPickupDate(order.pickupPointId, orderDate)
        }
    }
}
</script>
<template>
    <div class="q-py-lg">
        <div class="row align-center">
            <div class="col-shrink column justify-center">
                <QBtn icon="arrow_back_ios" to="/profile" size="24px" flat color="accent" />
            </div>
            <div class="col-grow text-h1">Bestellungen</div>
        </div>

        <div class="q-py-md">
            <div v-if="orderStore.hasOrders && orderStore.loading">
                <div v-for="i in 5" :key="i">
                    <div class="row q-py-md">
                        <div class="col-grow">
                            <QSkeleton type="text" height="18px" width="100px" />
                            <QSkeleton type="text" height="14px" width="150px" />
                            <QSkeleton type="text" height="14px" width="160px" />
                            <QSkeleton type="text" height="18px" width="120px" class="q-mt-md" /> 
                        </div>
                    </div>
                    <QSeparator color="tertiary" size="4px" class="q-my-md dashed" v-if="i != 5" />
                </div>
            </div>

            <div v-else-if="!orderStore.loading && !orderStore.hasOrders">
                <div class="q-pa-md text-center">
                    <QIcon name="sym_r_potted_plant" size="100px" color="accent" />
                    <div class="text-h4 q-mt-md">Du hast noch nichts bei uns bestellt.</div>
                </div>
            </div>

            <div v-else>
                <div v-for="(orderItem, key) in orderStore.orders" :key="orderItem.id">
                    <ActionItem :title="orderItem.date" @click="$router.push(`/profile/orders/${orderItem.id}`)">
                        <div class="text-micro">Bestellnummer: {{ orderItem.id }}</div>
                        <div><span class="text-bold">{{ cartStore.getFormattedPrice(orderItem.totals.total) }}</span> ({{ Object.keys(orderItem.items).length }} Produkte)</div>
                        <div class="q-mt-md">
                            <div v-if="orderItem.status === 'processing'" class="text-micro">
                                <div>Abholung:</div>
                                <div><span class="text-bold">{{ getNextPickupDate(orderItem).toLocaleDateString('de-DE', { weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric' }) }}</span> 
                                    ({{ getPickupPoint(orderItem).acf.pickup_time.time_from.slice(0, 5) }} - {{ getPickupPoint(orderItem).acf.pickup_time.time_until.slice(0, 5) }})</div>
                                <div>{{ getPickupPoint(orderItem).title.rendered }}</div>
                                <div>{{ getPickupPoint(orderItem).acf.location.street_name }} 
                                    {{ getPickupPoint(orderItem).acf.location.street_number }}</div>
                                <div>{{ getPickupPoint(orderItem).acf.location.post_code }} 
                                    {{ getPickupPoint(orderItem).acf.location.city }}
                                </div>
                            </div>
                            <div v-else>Status: {{ orderStore.getStatusLabel(orderItem.status) }}</div>
                        </div>
                    </ActionItem>
                    <QSeparator color="tertiary" size="4px" class="q-my-md dashed" v-if="key != orderStore.orders.length - 1" />
                </div>
            </div>
        </div>
    </div>
</template>