<script>
import RegisterForm from '@/components/Globals/RegisterForm.vue'

import { mapStores } from 'pinia'
import { useUserStore } from '@/stores/user.js'
//import axios from 'axios'

export default {
    data() {
        return {
            loading: false,
            isCustomer: false,
            privacyPolicyAgreed: false,
            stepValid: false,
            redirectUri: window.location.origin + '/checkout/user',
        }
    },

    components: {
        RegisterForm
    },

    mounted() {
        if (this.userStore.isAuthenticated && this.userStore.hasBilling) {
            console.log("User is authenticated and has billing")
            this.$emit('done')
        }
    },

    computed: {
        ...mapStores(useUserStore),
    },

    methods: {
        setValidation(valid) {
            console.log("Validation", valid)
            this.stepValid = valid
        },

        async handleRegistration() {
            const formData = this.$refs.registerForm.form

            if (this.userStore.isAuthenticated && this.userStore.wpUser != null) {
                this.userStore.pushUserMeta({
                    billing_first_name: formData.firstName,
                    billing_last_name: formData.lastName,
                    billing_address_1: formData.street,
                    billing_postcode: formData.zip,
                    billing_city: formData.city,
                    billing_country: formData.country,
                    billing_email: formData.email,
                    billing_phone: formData.phone,
                }).then(() => {
                    this.$emit('done')
                })
            } else {
                const userData = {
                    email: formData.email,
                    password: formData.password,
                    billing: {
                        firstName: formData.firstName,
                        lastName: formData.lastName,
                        street: formData.street,
                        zip: formData.zip,
                        city: formData.city,
                        country: formData.country,
                        email: formData.email,
                        phone: formData.phone,
                    }
                }

                this.userStore.registerUser(userData).then(() => {
                    this.$emit('done')
                })
            }

        },
    }
}
</script>
<template>
    <div class="col justify-center">
        <div class="row items-center no-wrap q-px-md q-pb-lg" v-if="userStore.isAuthenticated && !userStore.hasBilling">
            <QIcon name="info" color="accentLight" size="20px" />
            <div class="q-px-md">Wir brauchen noch ein paar zusätzliche Informationen um Ihren Kauf abzuschließen.</div>
        </div>
        <RegisterForm @validated="setValidation" ref="registerForm" />
        <div class="q-mt-xl">
            <QBtn 
                class="full-width q-mb-md" 
                color="accent" 
                text-color="black" 
                @click="handleRegistration" :disabled="!stepValid" 
                :loading="this.userStore.loading"
            >Zum Abschluss</QBtn>
            <QBtn outline class="full-width" color="accent" @click="$emit('back')">Zurück zum Warenkorb</QBtn>
        </div>
    </div>
</template>