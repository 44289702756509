<!-- eslint-disable vue/multi-word-component-names -->
<script>
//import VisitorSearch from '@/components/VisitorSearch.vue'
import PickupPlaceSearch from '@/components/PickupPoint/PickupPlaceSearch.vue'
import PickupPoint from '@/components/PickupPoint/PickupPoint.vue'
import CMSPage from '@/components/Globals/CMSPage.vue'

import { mapStores } from 'pinia'
import { usePickupPointStore } from '@/stores/pickupPoint'

export default {
    data() {
        return {
            pickupPoints: [],
            products: [],
            searchString: '',
            searching: false,
            loading: false,
            pickupSelectorExpanded: false,
        }
    },

    components: {
        //VisitorSearch,
        PickupPlaceSearch,
        PickupPoint,
        CMSPage
    },

    emits: ['content-loading', 'content-loaded'],

    mounted() {
        this.$emit('content-loading')
        this.pickupPointStore.fetchPickupPoints
    },

    computed: {
        ...mapStores(usePickupPointStore),

        showAutoComplete() {
            return this.searching;
        }
    },
    
    methods: {
        searchPickupPoints() {
            this.pickupPointStore.showSelector = true
        }
    }
}
</script>

<template>
    <div>
        <div class="q-px-md q-py-lg">
            <PickupPoint :searchString="searchString" :expanded="pickupSelectorExpanded" hideHeader />
            <h2 class="text-center text-h2">Finde deine Abholstation</h2>
            <PickupPlaceSearch @selected-place="searchPickupPoints" />
        </div>
        <CMSPage class="q-py-lg q-px-md" @content-loaded="this.$emit('content-loaded')" slug="visitor" />
    </div>
</template>