<script>
import { mapStores } from 'pinia'
import { useUserStore } from '@/stores/user'

export default {
    data() {
        return {
            loading: false,
            formData: {},
        }
    },

    props: {
        title: String,
        fields: Array,
        placeholder: String,
        expanded: Boolean,
        disabled: Boolean
    },

    emits: ['expand', 'collapse', 'save'],

    watch: {
        'userStore.loading' : {
            handler(newValue) {
                if (!newValue) {
                    this.loading = false
                    !this.expanded || this.$emit('collapse')
                }
            }
        }
    },

    mounted() {
        this.resetFormData()
    },

    computed: {
        ...mapStores(useUserStore),

        hasChanges() {
            for (const key in this.formData) {
                if (this.formData[key] !== this.fields.find(field => field.key === key).value) {
                    return true
                }
            }

            return false
        },

        hasValue() {
            for (const key in this.formData) {
                if (this.formData[key]) {
                    return true
                }
            }

            return false
        }
    },

    methods: {
        saveFields() {
            this.loading = true
            let changedFields = []

            for (const key in this.formData) {
                if (this.formData[key] !== this.fields.find(field => field.key === key).value) {
                    changedFields[key] = this.formData[key]
                }
            }

            this.$emit('save', changedFields)

            if (changedFields['password']) {
                this.formData['password'] = ''
            }
        },

        resetFormData() {
            this.fields.forEach(field => {
                this.formData[field.key] = field.value;
            });
        },

        collapse() {
            this.resetFormData()
            this.$emit('collapse')
        }
    }
}
</script>

<template>
    <div class="q-py-lg">
        <ActionItem :title="title" @click="disabled || $emit('expand')" :disabled="disabled">
            <div v-if="hasValue">
                <div v-for="field in fields" :key="field.key" :style="{'display' : field.inline ? 'inline' : 'block'}">
                    {{ field.before }}{{ field.type !== 'toggle' ? field.value : '' }}{{ field.after }}
                </div>
            </div>
            <div v-else class="text-lightGrey">
                {{ placeholder }}
            </div>
        </ActionItem>
        
        <QSlideTransition>
            <div v-if="expanded">
                <QForm @submit="saveFields()">
                    <!-- Add rules -->
                    <div v-for="field in fields" :key="field.key" class="q-my-md">
                        <QToggle
                            v-if="field.type === 'toggle'"
                            v-model="formData[field.key]"
                            :label="field.label"
                        />

                        <QInput
                            v-else-if="field.type === 'password'"
                            v-model="formData[field.key]"
                            :label="field.label"
                            lazy-rules
                            type="password"
                        />

                        <QInput
                            v-else
                            v-model="formData[field.key]"
                            :label="field.label"
                            lazy-rules
                        />
                    </div>
                    
                    <Transition 
                        enter-active-class="animate__animated animate__faster animate__fadeInUp" 
                        leave-active-class="animate__animated animate__faster animate__fadeOutDown"
                    >
                        <QBtn
                            v-if="hasChanges"
                            label="Speichern"
                            type="submit"
                            color="accent"
                            text-color="darkGrey"
                            class="full-width q-mt-lg"
                            :loading="loading"
                            @click="saveFields()"
                        />
                    </Transition>
                    <QBtn label="Abbrechen" flat color="accent" class="full-width" @click="collapse()" />
                </QForm>

            </div>
        </QSlideTransition>

    </div>
</template>