<script>
import QuantityStepper from "@/components/QuantityStepper.vue";

import { mapStores } from 'pinia'
import { useUserStore } from '@/stores/user'
import { useCartStore } from '@/stores/cart'

import ColorThief from 'colorthief';

export default {
    data() {
        return {
            productItem: this.productItemInit,
            loading: false,
            removeLock: false,
            selectedVariation: this.productItemInit.variations ? Object.values(this.productItemInit.variations)[0] : null,
            flagClass: '',
            imageDimensions: {
                width: 0,
                height: 0
            }
        }
    },

    props: {
        productItemInit: Object,
        id: Number,
        hideQuantityStepper: Boolean
    },

    components: {
        QuantityStepper,
    },

    mounted() {
        this.getFlagColorClass()
    },

    watch: {
        selectedVariation:{
            immediate: true,
            handler() {
                if (this.selectedVariation) {
                    this.productItem.price = this.selectedVariation.price_html;
                    this.productItem.imageUrl = this.selectedVariation.image.full_src;
                }
            }
        },
    },

    computed: {
        ...mapStores(useUserStore, useCartStore),

        variationOptions() {
            if (this.productItem.variations === undefined) return [];

            return this.productItem.variations.map(variation => {
                 // Get the first attribute's value as the label
                let label = Object.values(variation.attributes)[0];

                return {
                    label: label,
                    value: variation
                };
            });
        },

        cartItem() {
            if (this.productItem.variations) {
                return this.cartStore.getCartItem(this.selectedVariation.variation_id);
            } else {
                return this.cartStore.getCartItem(this.productItem.id);
            }
        },

        minimumQuantity() {
            return Number(this.productItem.measure.min_quantity != '' ? this.productItem.measure.min_quantity : this.productItem.measure.increment);
        },

        maximumQuantity() {
            return this.productItem.variations ? this.selectedVariation.max_qty : this.productItem.stockQuantity;
        },

        imageClass() {
            const { width, height } = this.imageDimensions;
            if (width > height) {
                return 'landscape';
            } else {
                return 'portrait';
            }
        }

    },

    methods: {
        addToCart() {
            this.loading = true;

            this.cartStore.addToCart(this.selectedVariation ? this.selectedVariation.variation_id : this.id, 
                this.selectedVariation ? this.selectedVariation.attributes : null).then(() => {
                this.loading = false;
            })
        },

        removeFromCart() {
            this.loading = true;

            this.cartStore.removeFromCart(this.cartItem.item_key).then(() => {
                this.loading = false;
            });

        },

        updateQuantity(quantity) {
            this.loading = true;

            this.cartStore.updateQuantity(this.cartItem.item_key, quantity).then(() => {
                this.loading = false;
            });
            
        },

        selectVariation() {
            this.productItem.price = this.selectedVariation.price_html;
            this.productItem.imageUrl = this.selectedVariation.image.full_src;
        },

        getFlagColorClass() {
            const colorThief = new ColorThief();

            if (!this.productItem.imageUrl || this.productItem.imageUrl == '') {
                this.flagClass = 'bg-tertiary text-accentLight'
                return
            }

            const url = new URL(this.productItem.imageUrl);
            
            const img = new Image();
            img.crossOrigin = 'Anonymous';
            img.src = url;
            img.onload = () => {
                const rgb = colorThief.getColor(img);
                const hsl = this.rgbToHsl(...rgb);
                const lightness = hsl[2];
                this.flagClass = lightness > 0.5 ? 'bg-tertiary text-accentLight' : 'bg-accentPale text-primary';
            };
        },

        rgbToHsl(r, g, b) {
            r /= 255, g /= 255, b /= 255;
            const max = Math.max(r, g, b), min = Math.min(r, g, b);
            let h, s, l = (max + min) / 2;
            if(max == min){
                h = s = 0; // achromatic :)
            } else {
                const d = max - min;
                s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
                switch(max){
                case r: h = (g - b) / d + (g < b ? 6 : 0); break;
                case g: h = (b - r) / d + 2; break;
                case b: h = (r - g) / d + 4; break;
                }
                h /= 6;
            }
            return [h, s, l];
        },

        getImageDimensions() {
            const img = this.$refs.productImage.$el.querySelector('img');
            if (img) {
                this.imageDimensions.width = img.naturalWidth;
                this.imageDimensions.height = img.naturalHeight;
            }
        }
        
    }
}
</script>

<template>
    <div class="product-card q-pa-md">
        <q-card bordered style="max-width: 400px;" class="bg-primary column no-wrap full-height">
            <div class="overflow-hidden bg-accentLight">
                <q-responsive :ratio="16/9">
                    <QImg 
                        v-if="productItem.imageUrl" 
                        :src="productItem.imageUrl" 
                        class="q-pa-md product-image"
                        :class="imageClass" 
                        ref="productImage" 
                        alt="Product Image"
                        @load="getImageDimensions"
                    />
                    <div v-else class="bg-accentPale flex justify-center content-center">
                        <img src="@/assets/logo.png" style="width: 120px; height: 120px;" />
                    </div>
                </q-responsive>
            </div>
            <div
                v-for="(flag, index) in productItem.flags"
                class="absolute flex column justify-center content-center product-flag"
                :style="{ top: `${index * 40 + 15}px` }"
                :key="index"
            >
                <div class="flag-content-before" :class="flagClass"></div>
                <div class="q-px-sm flag-content full-height flex content-center q-px-sm" :class="flagClass">{{ flag }}</div>
                <div class="flag-content-after" :class="flagClass"></div>
            </div>
            <q-card-section class="col q-px-md q-py-lg column justify-between">
                <div>
                    <div class="row">
                        <div class="col-8">
                            <div class="text-h6 product-title">{{ productItem.name }}</div>
                            <div class="">
                                von <RouterLink :to="`/producers/${productItem.producer.ID}`" class="text-accent">
                                    {{ productItem.producer?.display_name }}
                                </RouterLink>
                            </div>
                        </div>
                        <div class="col-4 text-right">
                            <div class="text-h6" v-html="selectedVariation ? this.cartStore.getFormattedPrice(parseInt(selectedVariation.display_price * 100)) : productItem.price"></div>
                            <div class="text-caption">per {{ minimumQuantity === 1 ? '' : minimumQuantity }} {{ productItem.measure.unit }}</div>
                        </div>
                    </div>
                </div>
                <div class="q-mt-md">
                    {{ selectedVariation ? selectedVariation.description : productItem.description }}
                </div>
                <q-card-actions align="center" class="q-px-none q-mt-md">
                    <QSelect 
                        outlined 
                        v-if="productItem.variations && productItem.variations.length > 1"
                        v-model="selectedVariation"
                        :options="variationOptions" 
                        label="Variation"
                        color="white"
                        class="full-width q-mb-md"
                        emit-value
                        map-options
                    />
                    <div v-if="maximumQuantity > 0" class="full-width">
                        <div class="text-micro text-accent full-width q-pa-sm" v-if="cartItem?.quantity.value > 0">Aktuell im Warenkorb</div>
                        <QuantityStepper
                            :initQuantity="cartItem ? Number(cartItem.quantity.value) : 0"
                            :increment="Number(productItem.measure.increment)"
                            :minQuantity="Number(minimumQuantity)"
                            :maxQuantity="Number(maximumQuantity)"
                            :unit="productItem.measure.unit"
                            :loading="this.loading"
                            class="full-width"
                            @add-to-cart="addToCart"
                            @quantity-change="updateQuantity"
                            @remove-from-cart="removeFromCart"
                        />
                    </div>
                    <div v-else>
                        <div class="text-white text-h6">Ausverkauft</div>
                    </div>
                </q-card-actions>
            </q-card-section>
        </q-card>
    </div>
</template>

<style lang="scss">
    .product-flag {
        width: auto !important;
        height: 25px !important;
        left: -4px;
        top: 12px;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 14px;
        z-index: 10;

        .flag-content-before {
            width: 4px;
            mask-image: url('@/assets/flag_before_mask.svg');
        }

        .flag-content-after {
            width: 7px;
            mask-image: url('@/assets/flag_after_mask.svg');
            margin-left: -1px;
        }

        .flag-content-before, .flag-content-after {
            background-color: inherit;
            height: 100%;
            mask-size: 100% 100%;
        }
    }

    .product-image {
        &.portrait .q-img__image {
            object-fit: contain !important;
        }
    }

    .product-title {
        hyphens: auto;
        -webkit-hyphens: auto;
        -moz-hyphens: auto;
        -ms-hyphens: auto;
        word-wrap: break-word;
        word-break: break-word;
    }
</style>