import axios from 'axios'
import { defineStore } from 'pinia'
import { useUserStore } from './user'

export const useProductStore = defineStore('product', {
    state: () => {
        return {
            loading: false,
            allProducts: [],
            numAvailableProducts: 0,
            sortOptions: [
                {name: 'Beliebt', metaKey: '_sold_counter', sortBy: 'meta_value_num', order: 'desc'},
                {name: 'Neu', sortBy: 'date', order: 'desc'},
                {name: 'Alphabetisch aufsteigend', sortBy: 'title', order: 'asc'},
                {name: 'Alphabetisch absteigend', sortBy: 'title', order: 'desc'},
                {name: 'Preis aufsteigend', metaKey: '_price', sortBy: 'meta_value_num', order: 'asc'},
                {name: 'Preis absteigend', metaKey: '_price', sortBy: 'meta_value_num', order: 'desc'}
            ],
            sort: {name: 'Beliebt', metaKey: '_sold_counter', sortBy: 'meta_value_num', order: 'desc'},
            pagination: {
                page: 1,
                perPage: 12
            },
            filter: {
                searchString: '',
                category: {id: -1, name: 'Alle Produkte'},
                producer: {id: -1, name: 'Alle'},
            }
        }
    },

    getters: {
        showPagination() {
            return this.numAvailableProducts > this.pagination.perPage
        },

        maxPages() {
            return Math.ceil(this.numAvailableProducts / this.pagination.perPage);
        },

        products() {
            return this.allProducts[this.pagination.page - 1] || []
        },
    },

    actions: {
        async fetchProducts(conservative = false) {
            this.loading = true

            const userStore = useUserStore()

            const pagination = JSON.stringify(this.pagination)
            const filter = JSON.stringify(this.filter)
            const sort = JSON.stringify(this.sort)

            if (!conservative) {
                this.allProducts = []
            }

            const params = new URLSearchParams({
                pickup_point_id: userStore.hasPickupPoint ? userStore.pickupPoint.id : '',
                pagination: pagination,
                filter: filter,
                sort: sort,
            })

            return axios.get(`${import.meta.env.VITE_API_URL}/wp-json/regiomarkt/v1/products?${params.toString()}`, {
                headers: {
                    Authorization: `Bearer ${userStore.token}`
                }
            }).then(response => {
                this.allProducts[this.pagination.page - 1] = response.data.products
                this.numAvailableProducts = response.data.numAvailableProducts
                this.loading = false
            })

            /*return fetch(`${import.meta.env.VITE_API_URL}/wp-json/regiomarkt/v1/products?${params.toString()}`, {
                method: 'GET',
                headers: {
                    //'X-WP-Nonce': userStore.nonce
                }
            })
            .then(response => response.json()).then(data => {
                this.allProducts[this.pagination.page - 1] = data.products
                this.numAvailableProducts = data.numAvailableProducts
                this.loading = false
            })*/
        },

        fetchProducerProducts() {
            this.loading = true

            const userStore = useUserStore()

            const pagination = JSON.stringify(this.pagination)
            const filter = JSON.stringify(this.filter)
            const sort = JSON.stringify(this.sort)

            const params = new URLSearchParams({
                pagination: pagination,
                filter: filter,
                sort: sort,
            })

            axios.get(`${import.meta.env.VITE_API_URL}/wp-json/regiomarkt/v1/producer/products?${params.toString()}`,
            {
                headers: {
                    Authorization: `Bearer ${userStore.token}`
                },
            }
            ).then(response => {
                this.allProducts[this.pagination.page - 1] = response.data.products
                this.numAvailableProducts = response.data.numAvailableProducts
                this.loading = false
            })
        },

        async updateStock(id, stock, variation_id = null) {
            this.loading = true
            const userStore = useUserStore()

            return axios.post(`${import.meta.env.VITE_API_URL}/wp-json/regiomarkt/v1/products/${id}`, {product_id: id, stock, variation_id}, {
                headers: {
                    Authorization: `Bearer ${userStore.token}`
                }
            })
        },

        async changeStatus(id, status) {
            const userStore = useUserStore()

            return axios.post(`${import.meta.env.VITE_API_URL}/wp-json/regiomarkt/v1/products/${id}`, {status: status}, {
                headers: {
                    Authorization: `Bearer ${userStore.token}`
                }
            })
        },

        setPage(page) {
            this.pagination.page = page
        },

        setFilter(filter) {
            this.filter = filter
            this.resetPagination()

            const userStore = useUserStore()

            if (userStore.isProducer)
                this.fetchProducerProducts()
            else
                this.fetchProducts()
        },

        setSort(sort) {
            this.sort = sort
            this.resetPagination()

            const userStore = useUserStore()

            if (userStore.isProducer)
                this.fetchProducerProducts()
            else
                this.fetchProducts()
        },

        resetPagination() {
            if (this.pagination.page > 1)
                this.pagination.page = 1
        },

        isPageLoaded(page) {
            return this.allProducts[page - 1] !== undefined 
        },

        getProduct(id) {
            return this.allProducts.find(product => product.id === id)
        },

        getVariation(productId, variationId) {
            return this.getProduct(productId).variations.find(variation => variation.variation_id === variationId)
        }
    }
})