<script>
import { mapStores } from 'pinia'
import { useUserStore } from '@/stores/user'

import ProfileSettingsItem from '@/components/Profile/ProfileSettingsItem.vue'

import axios from 'axios';

export default {
    data() {
        return {
            loading: false,
            loadingPasswordChange: false,
            expanded: null,
            formData: null,
            rules: {
                name: [
                    v => !!v || 'Name ist erforderlich',
                    v => (v && v.length >= 2) || 'Name muss mindestens 3 Zeichen lang sein',
                ],
                email: [
                    v => !!v || 'E-Mail ist erforderlich',
                    v => /.+@.+\..+/.test(v) || 'E-Mail muss gültig sein',
                ],
                phone: [
                    v => !!v || 'Telefonnummer ist erforderlich',
                    v => (v && v.length >= 10) || 'Telefonnummer muss mindestens 10 Zeichen lang sein',
                ],
                street: [
                    v => !!v || 'Straße ist erforderlich',
                    v => (v && v.length >= 3) || 'Straße muss mindestens 3 Zeichen lang sein',
                ],
                zipCode: [
                    v => !!v || 'PLZ ist erforderlich',
                    v => (v && v.length >= 4) || 'PLZ muss mindestens 4 Zeichen lang sein',
                ],
                city: [
                    v => !!v || 'Ort ist erforderlich',
                    v => (v && v.length >= 3) || 'Ort muss mindestens 3 Zeichen lang sein',
                ],
                country: [
                    v => !!v || 'Land ist erforderlich'
                ],
            },
            countries: [
                { label: 'Österreich', value: 'AT' },
                { label: 'Deutschland', value: 'DE' },
                { label: 'Schweiz', value: 'CH' },
            ],
            showPassword: false,
        }
    },

    components: {
        ProfileSettingsItem,
    },

    computed: {
        ...mapStores(useUserStore),

        contactDetailsFields() {
            let fields = [
                                {
                    key: 'phone',
                    label: 'Telefonnummer',
                    value: this.userStore.wpUser.meta.billing.phone,
                }
            ]

            if (this.userStore.userProvider === 'auth0') {
                fields.push({
                    key: 'email',
                    label: 'E-mail Adresse',
                    value: this.userStore.wpUser.meta.billing.email,
                })
            }

            return fields
        }
    },

    mounted() {
        let { email, phone, address_1, postcode, city, country } = this.userStore.wpUser.meta.billing

        this.formData = {
            name: this.userStore.user.name,
            email, phone, address_1, postcode, city, country,
            password: '',
            privacyPolicyAgreed: false,
        }
    },

    methods: {
        async saveBillingFields(fields) {
            this.loading = true

            if (Object.keys(fields).includes('email')) {
                this.handleEmailChange(fields.email)
                delete fields.email
            }

            let meta = {}

            for (const key in fields) {
                meta['billing_' + key] = fields[key]
            }

            console.log('Pushing billing meta', meta)

            this.userStore.pushUserMeta(meta).then(response => {
                console.log(response)
                this.userStore.fetchUser()
                this.loading = false
            })
        },

        async handlePasswordChange() {
            if (this.userStore.userProvider !== 'auth0')
                return;

            this.loadingPasswordChange = true
            return axios.post(`https://${this.userStore.auth0.clientOptions.domain}/dbconnections/change_password`, {
                client_id: this.userStore.auth0.clientOptions.clientId,
                email: this.userStore.user.email,
                connection: 'Username-Password-Authentication'
            }).then(() => {
                this.$q.notify({
                    message: `Ein Link zum Ändern des Passworts wurde an ${this.userStore.user.email} gesendet.`,
                    color: 'positive',
                    position: 'top',
                    timeout: 3000
                
                })
                this.loadingPasswordChange = false
            }).catch(error => {
                this.$q.notify({
                    message: `Fehler beim Ändern des Passworts: ${error.response.data.error_description}`,
                    color: 'negative',
                    position: 'top',
                    timeout: 3000
                
                })
                console.error(error)
                this.loadingPasswordChange = false
            })
        },

        handleEmailChange(email) {
            this.userStore.changeUserEmail(email).then(response => {
                console.log(response)
            })
        }
    }
}
</script>
<template>
    <div class="q-py-lg">
        <div class="row align-center q-mb-lg">
            <div class="col-shrink column justify-center">
                <QBtn icon="arrow_back_ios" to="/profile" size="24px" flat color="accent" />
            </div>
            <div class="col-grow text-h1">Kundenkonto</div>
        </div>

        <div class="q-my-md">
            <ProfileSettingsItem v-if="!userStore.fullName"
                title="Name"
                placeholder="Noch kein Name hinterlegt."
                :expanded="expanded === 'name'"
                :disabled="expanded && expanded !== 'name'"
                :fields="[
                    {
                        key: 'first_name',
                        label: 'Vorname',
                        value: '',
                        after: ' '
                    },
                    {
                        key: 'last_name',
                        label: 'Nachname',
                        value: '',
                        inline: true
                    }
                ]"

                @save="saveBillingFields"
                @expand="this.expanded='name'"
                @collapse="this.expanded = null"
            />

            <ActionItem v-else title="Name" disabled hideIcon>
                {{ userStore.fullName }}
                <QTooltip class="q-pa-md" color="accent" text-color="darkGrey">
                    Um Deinen Namen zu ändern, schreibe uns bitte per E-Mail!
                </QTooltip>
            </ActionItem>

            <QSeparator color="tertiary" size="4px" class="q-my-md dashed" />

            <ProfileSettingsItem 
                title="Adresse"
                placeholder="Noch keine Adresse hinterlegt."
                :expanded="expanded === 'address'"
                :disabled="expanded && expanded !== 'address'"
                :fields="[
                    {
                        key: 'address_1',
                        label: 'Straße Nr. / Stiege / ...',
                        value: userStore.wpUser.meta.billing.address_1,
                        after: ', '
                    },
                    {
                        key: 'postcode',
                        label: 'PLZ',
                        value: userStore.wpUser.meta.billing.postcode,
                        after: ' ',
                        inline: true,
                    },
                    {
                        key: 'city',
                        label: 'Ort',
                        value: userStore.wpUser.meta.billing.city,
                        inline: true
                    }
                ]" 
                
                @save="saveBillingFields"
                @expand="this.expanded='address'"
                @collapse="this.expanded = null"
            />

            <QSeparator color="tertiary" size="4px" class="q-my-md dashed" />

            <ProfileSettingsItem 
                title="Kontaktdaten"
                placeholder="Noch keine Kontaktdaten hinterlegt."
                :expanded="expanded === 'contact'"
                :disabled="expanded && expanded !== 'contact'"
                :fields="contactDetailsFields" 
                
                @save="saveBillingFields"
                @expand="this.expanded='contact'"
                @collapse="this.expanded = null"
            />

            <QSeparator color="tertiary" size="4px" class="q-my-md dashed" />

            <!--<ProfileSettingsItem 
                title="Zahlungsmethode"
                :expanded="expanded === 'payment'"
                :disabled="expanded && expanded !== 'payment'"
                :fields="[
                    {
                        key: 'email',
                        label: 'E-mail Adresse',
                        value: userStore.wpUser.meta.billing.email,
                    },
                ]" 
                
                @expand="this.expanded='payment'"
                @collapse="this.expanded = null"
            />

            <QSeparator color="tertiary" size="4px" class="q-my-md dashed" />-->

            <ActionItem 
                title="Passwort ändern"
                :loading="loadingPasswordChange" 
                @click="handlePasswordChange()" :disabled="userStore.userProvider !== 'auth0'" :hideIcon="userStore.userProvider !== 'auth0'"
            >
                <div v-if="userStore.userProvider === 'auth0'">Du bekommst einen Link zugesandt, mit dem Du dein Passwort ändern kannst.</div>
                <div v-else>Du hast dich mit {{ userStore.userProviderLabel }} angemeldet. Du kannst dein Passwort nur dort ändern.</div>
            </ActionItem>

            <!--<QSeparator color="tertiary" size="4px" class="q-my-md dashed" />

            <ProfileSettingsItem 
                title="Newsletter"
                :expanded="expanded === 'newsletter'"
                :disabled="expanded && expanded !== 'newsletter'"
                :fields="[
                    {
                        key: 'newsletter',
                        label: 'Newsletter abbonieren',
                        type: 'toggle',
                        value: false,
                    },
                ]" 
                
                @expand="expanded = 'newsletter'"
                @collapse="expanded = null"
            />-->
        </div>
    </div>
</template>