<script>
import { mapStores } from 'pinia'
import { useUserStore } from '@/stores/user.js'
import { useOrderStore } from '@/stores/order';
import { useNavigationStore } from '@/stores/navigation';

export default {
    computed: {
        ...mapStores(useUserStore, useOrderStore, useNavigationStore),
    },
}
</script>
<template>
    <div class="q-py-lg">
        <div class="text-center q-mb-lg">
            <h1 class="text-h1">Hallo {{ userStore.wpUser?.meta.billing.first_name }}</h1>
            <div v-if="orderStore.hasOrders">Du hast schon {{ Object.keys(orderStore.orders).length }} mal lokale Produzent:innen unterstützt. Danke!</div>
            <div v-else>Du hast bei uns noch nicht bestellt. Stöber dich gerne rein!</div>
        </div>

        <Transition 
            enter-active-class="animate__animated animate__fadeInLeft animate__faster animate__delay-1s" 
            leave-active-class="animate__animated animate__faster animate__fadeOutRight"
        >
            <div v-if="!orderStore.hasOrders && orderStore.loading">
                <div class="row q-py-md q-gutter-md">
                    <div class="col-shrink column justify-center">
                        <QSkeleton type="QAvatar" height="24px" width="24px" />
                    </div>
                    <div class="col-grow">
                        <QSkeleton type="text" height="18px" width="100px" class="q-mb-sm" />
                        <QSkeleton type="text" height="14px" width="150px" />
                    </div>
                </div>
            </div>
            <div v-else>
                <ActionItem title="Deine Bestellungen" @click="$router.push('/profile/orders')">
                    <template #prepend><QIcon name="sym_r_grocery" size="24px" color="accent" /></template>
                    <div v-if="orderStore.numberOpenOrders">{{ orderStore.numberOpenOrders }} offene Bestellung{{ orderStore.numberOpenOrders === 1 ? '' : 'en'}}</div>
                </ActionItem>
            </div>
        </Transition>
        <QSeparator color="tertiary" size="4px" class="q-my-md dashed" />
        
        <ActionItem title="Kundenkonto" @click="$router.push('/profile/settings')">
            <template #prepend><QIcon name="sym_r_settings" size="24px" color="accent" /></template>
        </ActionItem>

        <QSeparator color="tertiary" size="4px" class="q-my-md dashed" />
        
        <ActionItem title="Ausloggen" @click="userStore.logout()" icon="sym_r_logout" />

        <div class="q-mt-xl text-center">
            <div class="text-h4">Schwierigkeiten oder Vorschläge zum Verbessern?</div>
            <QSeparator width="150px" class="dashed q-my-md q-mx-auto" />
            <div class="">
                Inspiration ist immer Willkommen! 
                Werden deine Ideen umgesetzt bekommst du eine Gutschrift für deinen Einkauf.
            </div>
            <QBtn label="Idee senden" class="q-mt-xl q-mx-auto" color="accent" text-color="black" @click="navigationStore.openFeedbackDialog()" />
        </div>
    </div>
</template>