<!-- eslint-disable vue/multi-word-component-names -->
<script>
import CheckoutCart from '@/components/Checkout/CheckoutCart.vue';
import CheckoutCustomer from '@/components/Checkout/CheckoutCustomer.vue';
import CheckoutPayment from '@/components/Checkout/CheckoutPayment.vue';
import CheckoutConfirmation from '@/components/Checkout/CheckoutConfirmation.vue';

import { mapStores } from 'pinia'
import { useCartStore } from '@/stores/cart'
import { useUserStore } from '@/stores/user'

export default {
    data() {
        return {
            checkoutSteps: [
                { name: 'cart', title: 'Warenkorb' },
                { name: 'user', title: 'Kundendaten' },
                { name: 'payment', title: 'Abschluss' }
            ],
        }
    },

    components: {
        CheckoutCart,
        CheckoutCustomer,
        CheckoutPayment,
        CheckoutConfirmation
    },

    mounted() {
        this.cartStore.fetchCart();
    },

    computed: {
        ...mapStores(useCartStore, useUserStore),

        currentStep() {
            return this.$route.params.step
        }
    },

    methods: {
        nextStep() {
            const currentIndex = this.checkoutSteps.findIndex(step => step.name === this.currentStep)
            let nextIndex = (currentIndex + 1) % this.checkoutSteps.length

            if (this.checkoutSteps[nextIndex].name === 'user' && this.userStore.isAuthenticated && this.userStore.hasBilling) {
                nextIndex = (nextIndex + 1) % this.checkoutSteps.length
            }
            
            const nextStep = this.checkoutSteps[nextIndex].name
            this.$router.push({ params: { step: nextStep } })
        },

        prevStep() {
            const currentIndex = this.checkoutSteps.findIndex(step => step.name === this.currentStep)
            const prevIndex = (currentIndex - 1) % this.checkoutSteps.length

            if (this.checkoutSteps[prevIndex].name === 'user' && this.userStore.isAuthenticated && this.userStore.hasBilling) {
                this.prevIndex = (prevIndex - 1) % this.checkoutSteps.length
            }

            const prevStep = this.checkoutSteps[prevIndex].name
            this.$router.push({ params: { step: prevStep } });
        },

        isStepDone(step) {
            return this.checkoutSteps.findIndex(stepItem => stepItem.name === step) <= this.checkoutSteps.findIndex(stepItem => stepItem.name === this.$route.params.step);
        },

        handleLogin() {
            if (this.userStore.isAuthenticated) {
                this.nextStep()
            } else {
                this.userStore.auth0.loginWithRedirect({
                    appState: { target: '/checkout/user' }
                })
            }
        }
    }
}
</script>

<template>
    <div class="q-py-lg container-sm">
        <div v-if="currentStep === 'confirmation'">
            <CheckoutConfirmation />
        </div>
        <div v-else>
            <div class="text-h3 text-center">
                <span v-if="currentStep === 'cart'">Warenkorb</span>
                <span v-else-if="currentStep === 'user'">Kundendaten</span>
                <span v-else-if="currentStep === 'payment'">Abschluss</span>
            </div>
            <QStepper :model-value="$route.params.step" active-color="accent" active-icon="none" ref="stepper" animated contracted flat>
                <QStep name="cart" title="Warenkorb" :done="isStepDone('cart')" color="transparent">
                    <CheckoutCart @done="nextStep" @back="$router.push('/')" />
                    <div class="q-mt-xl row reverse q-col-gutter-md">
                        <div class="col-12 col-md-6">
                            <QBtn class="full-width q-mb-md" color="accent" text-color="black" @click="handleLogin()">
                                {{ userStore.isAuthenticated ? 'Weiter zum Abschluss' : 'Zur Zahlung'}}
                            </QBtn>
                        </div>
                        <div class="col-12 col-md-6">
                            <QBtn outline to="/" class="full-width" color="accent">Weiter einkaufen</QBtn>
                        </div>
                    </div>
                </QStep>

                <QStep name="user" title="Kundendaten" :done="isStepDone('user')">
                    <CheckoutCustomer @done="nextStep" @back="prevStep" />
                </QStep>

                <QStep name="payment" title="Abschluss" :done="isStepDone('payment')">
                    <CheckoutPayment @back="$router.push({ params: { step: 'cart' } })" />
                </QStep>
            </QStepper>
        </div>
    </div>
</template>