<script>
import axios from 'axios'

import { mapStores } from 'pinia';
import { useUserStore } from '@/stores/user';

export default {
    data() {
        return {
            loading: false,
            formValues: {},
            subjectOptions: ['Ware beschädigt', 'Bestellung nicht abgeholt', 'Sonstiges']
        }
    },

    props: {
        cf7Id: Number
    },

    emits: ['ok', 'hide'],

    computed: {
        ...mapStores(useUserStore)
    },
    
    methods: {
        show() {
            this.$refs.dialog.show()
        },

        hide() {
            this.$refs.dialog.hide()
        },

        onDialogHide() {
            this.$emit('hide') 
        },

        onOkClick() {
            this.$emit('ok')
            this.hide()
        },

        onCancelClick() {
            this.hide()
        },

        sendForm() {
            let formData = new FormData()
            formData.append('userName', this.userStore.wpUser.meta.billing.first_name + ' ' + this.userStore.wpUser.meta.billing.last_name)
            formData.append('userEmail', this.userStore.wpUser.meta.billing.email)
            formData.append('orderId', this.$route.params.orderId)
            formData.append('subject', this.formValues.subject )
            formData.append('message', this.formValues.message)
            formData.append('_wpcf7_unit_tag', this.cf7Id)

            console.log(formData)

            this.loading = true

            axios.post(`https://api.regio-markt.com/wp-json/contact-form-7/v1/contact-forms/${this.cf7Id}/feedback`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(response => {
                this.loading = false
                this.onOkClick()
                this.$refs.form.reset()
                this.$q.notify({
                    message: response.data.message,
                    color: response.data.status == 'mail_sent' ? 'positive' : 'negative',
                    position: 'top'
                })
            }).catch(error => {
                this.loading = false
                console.log(error)
                this.$q.notify({
                    message: 'Fehler beim Senden der Nachricht',
                    color: 'negative',
                    position: 'top'
                })
            })
        }
    }
}
</script>

<template>
    <QDialog ref="dialog" @hide="onDialogHide">
        <QCard class="q-dialog-plugin q-pa-lg column container-sm">
            <QForm ref="form" @submit="sendForm()">
                <h3 class="q-mt-none text-center">Du hast ein Problem mit der Bestellung?</h3>
                <QSelect class="q-mt-md" v-model="formValues.subject" :options="subjectOptions" label="Problem" />
                <QInput type="textarea" class="q-mt-md" v-model="formValues.message" label="Nachricht" outlined />
                <QCardActions align="right" class="q-mt-md">
                    <QBtn color="accent" label="Abbrechen" flat @click="onCancelClick" />
                    <QBtn type="submit" class="q-px-md" color="accent" text-color="black" :loading="loading" label="Senden" />
                </QCardActions>
            </QForm>
        </QCard>
    </QDialog>
</template>