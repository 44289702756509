<script>
export default {
    props: {
        icon: {
            type: String,
            default() {
                return 'info'
            }
        },
        filled: Boolean,
        message: String,
        color: String,
        textColor: String,
        iconColor: String
    }
}
</script>

<template>
    <div class="row items-center no-wrap q-pa-md info-box" :class="filled ? `bg-${color}` : ''">
        <QIcon :name="icon" :color="iconColor ?? (filled ? textColor : color)" size="30px" />
        <div class="q-px-md text-left" :class="filled ? `text-${textColor}` : `text-${color}`">{{ message }}</div>
    </div>
</template>

<style scoped lang="scss">
    .info-box {
        border-radius: 8px;
    }
</style>