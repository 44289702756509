<script>
import { mapStores } from 'pinia'
import { useCartStore } from '@/stores/cart'
import { useUserStore } from '@/stores/user'

import { getCssVar } from 'quasar'

import { loadStripe } from '@stripe/stripe-js'

import CheckoutCart from './CheckoutCart.vue'
import PickupPointInfo from '@/components/PickupPoint/PickupPointInfo.vue'

import axios from 'axios'

export default {
    data() {
        return {
            loading: false,
            processingPayment: false,
            stripe: null,
            elements: null,
            stripeApiKey: import.meta.env.VITE_STRIPE_API_KEY,
            clientSecret: null,
            paymentElement: null,
            paymentFormValid: false,
            orderId: null,
        }
    },

    components: {
        CheckoutCart,
        PickupPointInfo,
    },

    mounted() {
        this.initializeStripe()
    },

    computed: {
        ...mapStores(useCartStore, useUserStore),
    },

    methods: {
        async initializeStripe() {
            this.loading = true

            await this.cartStore.initialized

            const options = {
                locale: 'de',
                mode: 'payment',
                currency: 'eur',
                amount: Number(this.cartStore.cart.totals.total),
                appearance: {
                    theme: 'flat',
                    labels: 'floating',
                    variables: {
                        colorPrimary: getCssVar('white'),
                        colorText: getCssVar('white'),
                        colorTextPlaceholder: getCssVar('lightGrey'),
                        colorTextSecondary: getCssVar('lightGrey'),
                        colorBackground: 'transparent',
                        borderRadius: '8px',
                        fontFamily: 'Montserrat',
                    },
                    rules: {
                        '.AccordionItem': {
                            backgroundColor: 'transparent',
                            border: `2px solid ${getCssVar('tertiary')}`
                        },
                        '.AccordionItem:hover': {
                            color: getCssVar('accentLight'),
                        },
                        '.Input': {
                            border: `1px solid ${getCssVar('accent')}`,
                            borderRadius: '8px',
                            color: getCssVar('darkGrey'),
                        },
                        '.Label': {
                            color: getCssVar('darkGrey'),
                        },
                        '.Menu': {
                            backgroundColor: getCssVar('white'),
                            border: `1px solid ${getCssVar('accent')}`,
                            borderRadius: '8px',
                        },
                        '.MenuIcon': {
                            backgroundColor: getCssVar('lightGrey'),
                        },
                        '.MenuIcon:hover': {
                            backgroundColor: getCssVar('grey'),
                        },
                        '.MenuAction': {
                            color: getCssVar('white'),
                            backgroundColor: getCssVar('lightGrey'),
                            fontWeight: 700,
                        },
                        '.MenuAction:hover': {
                            backgroundColor: getCssVar('lightGrey'),
                        },
                        '.PickerItem': {
                            color: getCssVar('darkGrey'),
                        },
                        '.PickerItem--selected': {
                            border: `1px solid ${getCssVar('tertiary')}`,
                            color: getCssVar('darkGrey'),
                        },
                        '.PickerItem--new': {
                            color: getCssVar('darkGrey'),
                        },
                        '.PickerAction': {
                            backgroundColor: 'transparent',
                            color: getCssVar('darkGrey'),
                        },
                        '.PickerAction:hover': {
                            backgroundColor: 'transparent',
                        },
                    }
                }
            }

            const paymentOptions = {
                layout: {
                    type: 'accordion',
                    defaultCollapsed: false,
                    radios: false,
                    spacedAccordionItems: true
                }
            }
            
            this.stripe = await loadStripe(this.stripeApiKey)
            this.elements = this.stripe.elements(options)
            this.paymentElement = this.elements.create('payment', paymentOptions)
            this.paymentElement.mount('#stripe-payment')
            this.paymentElement.on('change', event => {
                this.paymentFormValid = !event.error;
            });
            this.loading = false
        },

        handlePayment() {
            window.onbeforeunload = function() {
                return 'Es wird gerade eine Bezahlung durchgeführt, bitte bleiben Sie auf der Seite.'
            }

            this.elements.submit().then(result => {
                console.log(result)
                if (result.error) {
                    return
                }
                
                this.processingPayment = true
                
                this.createPaymentIntent().then(() => {
                    window.onbeforeunload = null
                    this.confirmPayment()
                    this.processingPayment = false
                })
            })
        },
        
        async createPaymentIntent() {
            return axios.get(`${import.meta.env.VITE_API_URL}/wp-json/stripe-rest/v1/payment-intent`, {
                headers: {
                    Authorization: `Bearer ${this.userStore.token}`,
                },
            }).then(response => {
                this.clientSecret = response.data.client_secret
                this.orderId = response.data.order_id
            }).catch(error => {
                console.error(error)
            })
        },

        async confirmPayment() {
            this.loading = true
            const result = await this.stripe.confirmPayment({
                elements: this.elements,
                clientSecret: this.clientSecret,
                confirmParams: {
                    return_url: `${window.location.origin}${window.location.hostname == 'sebastianlamprecht.github.io' ? '/regiomarkt-app/' : ''}?order_id=${this.orderId}`,
                }
            })
            if (result.error) {
                this.$q.notify({
                    message: result.error.message,
                    icon: 'sym_r_credit_card_off',
                    color: 'negative',
                    position: 'top',
                    timeout: 8000,
                })
            }
            this.loading = false
        },

    },
}
</script>
<template>
    <div>
        <CheckoutCart disable-actions />
        <div class="q-mt-xl q-mb-md text-center text-h5">Abholung</div>
        <PickupPointInfo stretched="lg" :pickupPoint="userStore.pickupPoint" />
        <div class="q-py-lg text-center">
            <div class="text-h3">Bezahlung</div>
            <div class="full-width row q-col-gutter-md" v-if="!stripe">
                <div class="col-12">
                    <QSkeleton type="rect" width="100%" height="60px" />
                </div>
                <div class="col">
                    <QSkeleton type="rect" width="100%" height="60px" />
                </div>
                <div class="col">
                    <QSkeleton type="rect" width="100%" height="60px" />
                </div>
                <div class="col-12">
                    <QSkeleton type="rect" width="100%" height="60px" />
                </div>
            </div>
            <div id="stripe-payment" class="q-my-lg"></div>
        </div>
        <QBtn 
            class="full-width q-mt-md" 
            color="accent" 
            text-color="black" 
            @click="handlePayment()" 
            :loading="processingPayment"
            :disabled="!stripe || loading || !paymentFormValid"
        >Kostenpflichtig bestellen</QBtn>
        <QBtn class="full-width q-mt-md" color="accent" outline @click="$emit('back')">Zurück zum Warenkorb</QBtn>
    </div>
</template>