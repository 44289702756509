import { defineStore } from 'pinia'
import { useAuth0 } from '@auth0/auth0-vue'
import { useCartStore } from '@/stores/cart'
import { usePickupPointStore } from '@/stores/pickupPoint'
import { base } from '@/router'
import VueCookies from 'vue-cookies'
import { Dialog } from 'quasar'
import { Notify } from 'quasar'
import LocationPermissionDialog from '@/dialogs/LocationPermissionDialog.vue'
import axios from 'axios'

let resolveInit

export const useUserStore = defineStore('user', {
    state: () => {
        return {
            wpUser: null,
            token: null,
            pickupPointId: null,
            location: null,
            orders: null,
            auth0: null,
            cookieAcceptance: VueCookies.isKey('cookie_acceptance') ? VueCookies.get('cookie_acceptance') : false,
            loading: false,
            inizialized: new Promise(resolve => {
                resolveInit = resolve
            }),
            cartStore: useCartStore(),
            pickupPointStore: usePickupPointStore(),
            time: new Date()
        }
    },

    getters: {
        hasPickupPoint() {
            return this.pickupPointId !== null || VueCookies.isKey('pickup_point')
        },

        hasBilling() {
            if (!this.wpUser || !this.wpUser.meta.billing)
                return false

            const { first_name, last_name, address_1, city, postcode, country, email } = this.wpUser.meta.billing;
            let requiredFields = [first_name, last_name, address_1, city, postcode, country, email]
            return requiredFields.every(field => field && field !== "")
        },

        isAuthenticated() {
            return this.auth0 ? this.auth0.isAuthenticated : null
        },

        user() {
            return this.auth0 ? this.auth0.user : null
        },

        fullName() {
            if (!this.wpUser || !this.wpUser.meta.billing || !this.wpUser.meta.billing.first_name || !this.wpUser.meta.billing.last_name)
                return null
            
            return this.wpUser?.meta.billing.first_name + ' ' + this.wpUser?.meta.billing.last_name
        },

        isProducer() {
            return this.wpUser?.user_roles.includes('producer')
        },

        pickupPoint() {
            if (!this.pickupPointStore.pickupPoints)
                return null

            if (this.wpUser?.user_roles.includes('producer')) {
                return {
                    id: -1
                }
            }
            
            return this.pickupPointStore.pickupPoints.find(point => point.id === this.pickupPointId)
        },

        nextPickupDate() {
            if (!this.pickupPoints || this.wpUser?.user_roles.includes('producer'))
                return null

            if (this.pickupPoint && this.pickupPoint.acf.pickup_time) {
                const weekdayMap = { 'sun': 0, 'mon': 1, 'tue': 2, 'wed': 3, 'thu': 4, 'fri': 5, 'sat': 6 };
                const weekday = weekdayMap[this.pickupPoint.acf.pickup_time.day.toLowerCase()];
                const nextPickupDate = new Date();
                nextPickupDate.setDate(nextPickupDate.getDate() + ((weekday + 7 - nextPickupDate.getDay()) % 7));
                return nextPickupDate.toLocaleDateString('de-DE', { weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric' });
            } else
                return null
        },

        userProvider() {
            return this.user?.sub.split('|')[0]
        },

        userProviderLabel() {
            switch (this.userProvider) {
                case 'google-oauth2':
                    return 'Google'
                case 'facebook':
                    return 'Facebook'
                case 'auth0':
                    return 'E-Mail und Passwort'
                default:
                    return this.userProvider
            }
        }
    },

    actions: {
        async initialize() {
            this.auth0 = useAuth0()

            await new Promise(resolve => {
                const checkLoading = setInterval(() => {
                    if (!this.auth0.isLoading) {
                        clearInterval(checkLoading);
                        resolve();
                    }
                }, 10);
            })

            if (this.isAuthenticated) {
                console.log("Is authenticated, fetching data")
                await this.fetchToken()
                await this.fetchUser()
            }
            
            await this.getPickupPoint()
            
            setInterval(() => {
                this.time = new Date()
            }, 500)
            
            console.log("User initialized", this.pickupPoint)
            resolveInit()

            return
        },

        setUser(user) {
            this.user = user
        },
        
        login() {
            this.auth0.loginWithRedirect({
                appState: { target: '/' }
            })
        },

        async logout() {
            this.cartStore.endSession()
            this.auth0.logout({ logoutParams: { returnTo: window.location.origin + base } })
        },

        async fetchToken() {
            return this.auth0.getAccessTokenSilently().then(token => {
                this.token = token
            }).catch(error => {
                console.log(error)
                return this.auth0.getAccessTokenWithPopup().then(token => {
                    this.token = token
                })
            })
        },

        async fetchUser() {
            // Get WordPress user from backend
            await axios.get(`${import.meta.env.VITE_API_URL}/wp-json/wp/v2/users/me`, {
                headers: {
                    Authorization: `Bearer ${this.token}`
                },
            }).then(response => {
                this.wpUser = response.data
            }).catch(error => {
                if (error.status === 401) {
                    console.log("User not in WordPress!")
                    this.wpUser = null
                }
            })

            return true
        },

        async pushUserMeta(meta) {
            if (!this.isAuthenticated)
                return false

            // TODO: Send meta that was set to server
            this.loading = true

            return axios.post(`${import.meta.env.VITE_API_URL}/wp-json/wp/v2/users/${this.wpUser.id}`,
                {
                    meta: meta
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${this.token}`
                    }
                }
            ).then(response => {
                console.log("User meta updated", response)
                this.wpUser = response.data
            }).catch(error => {
                console.error("Error updating user meta", error)
            }).finally(() => {
                this.loading = false
            })
        },

        async changeUserEmail(email) {
            if (!this.isAuthenticated)
                return false

            this.loading = true

            return axios.post(`${import.meta.env.VITE_API_URL}/wp-json/wp/v2/users/${this.wpUser.id}`,
                {
                    email: email,
                    meta: {
                        billing_email: email
                    }
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${this.token}`
                    }
                }
            ).then(response => {
                this.wpUser = response.data
            }).catch(error => {
                console.error("Error updating user email", error)
            })

            /*return axios.post(`https://${this.auth0.clientOptions.domain}/api/v2/${this.user.sub}`,{
                headers: {
                    Authorization: `Bearer ${this.token}`,
                }
            }, {
                client_id: this.auth0.clientOptions.clientId,
                email
            }).then(() => {
                this.$q.notify({
                    message: `E-Mail adresse erfolgreich geändert!`,
                    color: 'positive',
                    position: 'top',
                    timeout: 3000
                
                })
                this.loadingPasswordChange = false
            }).catch(error => {
                this.$q.notify({
                    message: `Fehler beim Ändern der E-Mail: ${error.response.data.error_description}`,
                    color: 'negative',
                    position: 'top',
                    timeout: 3000
                
                })
                console.error(error)
                this.loadingPasswordChange = false
            })*/

        },

        async registerUser(userData) {
            if (!userData || !userData.email || !userData.password) {
                console.error("No user data provided")
                return false
            }

            this.loading = true

            return axios.post(`https://${this.auth0.clientOptions.domain}/dbconnections/signup`, {
                client_id: this.auth0.clientOptions.clientId,
                email: userData.email,
                given_name: userData.firstName,
                family_name: userData.lastName,
                password: userData.password,
                connection: 'Username-Password-Authentication',
                user_metadata: { billing: JSON.stringify(userData.billing) },
            },
            {
                headers: {
                    'Content-Type': 'application/json'
                },
            }).then(response => {
                console.log("User registered", response)
            }).finally(() => {
                this.loading = false
            })
        },

        async getPickupPoint() {
            // Fix for changing to id system
            if (VueCookies.isKey('pickup_point') && isNaN(VueCookies.get('pickup_point')))
                VueCookies.remove('pickup_point')

            // Get pickup point from user meta
            if (this.wpUser?.meta.pickup_point && this.wpUser.meta.pickup_point !== '') {
                console.log("Pickup point set in user meta")
                const pickupPoint = JSON.parse(this.wpUser.meta.pickup_point)
                this.setPickupPoint(pickupPoint)
            } else if (this.isAuthenticated && VueCookies.isKey('pickup_point')) {
                // If no pickup point is set, and a pickup point is set in cookies, set it in user meta
                console.log("No pickup point set in user meta but found a cookie")
                const pickupPoint = JSON.parse(VueCookies.get('pickup_point'))
                await this.pickupPointStore.pushPickupPoint(pickupPoint).then(() => {
                    console.log("Pickup point set in user meta, removing cookie")
                    VueCookies.remove('pickup_point')
                })
            } else if (VueCookies.isKey('pickup_point')) {
                console.log("No user set, getting PickupPoint from cookie")
                const pickupPoint = JSON.parse(VueCookies.get('pickup_point'))
                this.setPickupPoint(pickupPoint)
            }

        },

        setPickupPoint(point_id) {
            this.pickupPointId = point_id
        },
        
        async getCoordinates() {
            if (this.location) {
                return this.location;
            } else {
                return new Promise((resolve, reject) => {
                    const dialog = Dialog.create({
                        component: LocationPermissionDialog
                    })
    
                    navigator.geolocation.getCurrentPosition(
                        position => {
                            const coordinates = {
                                latitude: position.coords.latitude,
                                longitude: position.coords.longitude
                            }

                            dialog.hide()
                            resolve(coordinates);
                        },
                        error => {
                            console.log(error)
                            dialog.hide()
                            Notify.create({
                                message: 'Problem bei der Standortermittlung. Bitte stelle sicher, dass Du die Standortfreigabe im Browser und in den Einstellungen aktiviert hast',
                                color: 'negative',
                                position: 'top',
                                icon: 'wrong_location',
                            
                            })
                            reject(error)
                        }, {
                            enableHighAccuracy: true,
                            timeout: 10000,
                        }
                    );
                });
            }
        },

        setLocation(place) {
            this.location = place
            this.pickupPointStore.setDistances()
        },

        setCookieAcceptance(level) {
            VueCookies.set('cookie_acceptance', level, '1Y')
            this.cookieAcceptance = level
        }

    },
})