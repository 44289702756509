import { defineStore } from 'pinia'
import { useUserStore } from './user'
import { useCartStore } from './cart'
import { Dialog } from 'quasar'
import VueCookies from 'vue-cookies'
import axios from 'axios'

let resolveInitPickupPoint;

export const usePickupPointStore = defineStore('pickupPoint', {
    state: () => {
        return {
            pickupPoints: null,
            loading: false,
            userStore: useUserStore(),
            cartStore: useCartStore(),
            showSelector: false,
            inizialized: new Promise(resolve => {
                resolveInitPickupPoint = resolve
            }),
        }
    },

    actions: {
        /**
         * Gets all available pickup points from the backend
         *
         * @async
         * @returns {unknown}
         */
        async fetchPickupPoints() {
            this.loading = true
            return axios(`${import.meta.env.VITE_API_URL}/wp-json/wp/v2/pickup_points`).then(response => {
                this.pickupPoints = response.data
                this.loading = false
                resolveInitPickupPoint()
            })
        },

        /**
         * Pushes the new pickup point to the server and saves it in the user meta or a cookie.
         *
         * @async
         * @param {*} pickupPoint
         * @returns {*}
         */
        async pushPickupPoint(pickupPointId) {
            this.loading = true

            let headers = {
                'Content-Type': 'application/json',
            }

            let params = {
                pickup_point_id: pickupPointId,
            }

            if (this.userStore.isAuthenticated) {
                headers['Authorization'] = `Bearer ${this.userStore.token}`
            } else {
                params.cart_key = this.cartStore.cartKey
            }

            return axios.post(`${import.meta.env.VITE_API_URL}/wp-json/regiomarkt/v1/change_pickup_point`, params, {
                headers: headers,
            }).then(() => {
                if (!this.userStore.isAuthenticated) {
                    VueCookies.set('pickup_point', encodeURIComponent(JSON.stringify(pickupPointId)), '30d')
                }
                
                this.userStore.setPickupPoint(pickupPointId)
                this.cartStore.fetchCart()
                this.loading = false
            })
        },

        getPickupPoint(id) {
            return this.pickupPoints.find(point => point.id === id)
        },

        /**
         * Selects a new pickup point and either saves it in user meta or a cookie.
         * It also provides the logic for removing products from the cart that are not available at the new pickup point.
         *
         * @async
         * @param {Object<PickupPoint>} pickupPoint
         * @returns {Promise<Void>}
         */
        changePickupPoint(pickupPoint) {
            return new Promise((resolve) => {
                if (this.userStore.pickupPoint && this.userStore.pickupPoint.id === pickupPoint.id) {
                    resolve()
                    return
                }

                let unavailableProducts = []

                this.cartStore.cart.items?.forEach(item => {
                    if (!item.pickupPoints.some(point => point.ID === pickupPoint.id)) {
                        unavailableProducts.push(item.name)
                    }
                })

                if (!this.cartStore.iseEmpty && unavailableProducts.length > 0) {
                    Dialog.create({
                        message: `
                        <div class="text-center">
                            <i class="q-icon notranslate material-icons q-mx-auto text-accent" style="font-size: 50px;" role="img" >production_quantity_limits</i>
                        </div>
                        <div class="text-bold q-mt-lg">Die folgenden Produkte sind nicht bei ${pickupPoint.title.rendered} abholbar:</div>
                        <div class="q-py-md">
                            <ul>
                                ${unavailableProducts.map(product => `<li>${product}</li>`).join('')}
                            </ul>
                        </div>
                        Sie werden aus dem Warenkorb entfernt wenn die Abholstation geändert wird.`,
                        html: true,
                        persistent: true,
                        ok: {
                            label: 'Abholpunkt ändern',
                            color: 'accent',
                            textColor: 'black',
                            class: 'q-px-md'
                        },
                        cancel: {
                            label: 'Abbrechen',
                            flat: true,
                            color: 'accent'
                        }
                    }).onOk(() => {
                        this.pushPickupPoint(pickupPoint.id).then(() => {
                            resolve()
                        })
                    }).onCancel(() => {
                        resolve('cancel') 
                    })
                } else {
                    this.pushPickupPoint(pickupPoint.id).then(() => {
                        resolve()
                    })
                }
            })
        },

        parseDate(input, format) {
            format = format || 'yyyy-mm-dd'; // default format
            var parts = input.match(/(\d+)/g), 
                i = 0, fmt = {};
            // extract date-part indexes from the format
            format.replace(/(yyyy|dd|mm)/g, function(part) { fmt[part] = i++; });
          
            return new Date(parts[fmt['yyyy']], parts[fmt['mm']]-1, parts[fmt['dd']]);
        },

        
        /**
         * Gets the next pickup point date for a given reference date and pickup point
         *
         * @param {Date} date
         * @param {Number} pickupPointId
         * @returns {Date}
         */
        nextPickupDate(pickupPointId, referenceDate = this.userStore.time) {
            const pickupDay = this.getPickupPoint(pickupPointId).acf.pickup_time.day
            const pickupTime = this.getPickupPoint(pickupPointId).acf.pickup_time.time_from

            let nextPickupDate = this.getNextOccurance(pickupDay, pickupTime, referenceDate)
            let deadline = this.nextOrderDeadline(pickupPointId, referenceDate)

            // If it is already past the deadline push to next week
            if (nextPickupDate < deadline) {
                nextPickupDate.setDate(nextPickupDate.getDate() + 7);
            }

            return nextPickupDate
        },

        nextOrderDeadline(pickupPointId, referenceDate = this.userStore.time) {
            const pickupPoint = this.getPickupPoint(pickupPointId)
            const weekday = pickupPoint.deadline.day.value
            const time = pickupPoint.deadline.time

            return this.getNextOccurance(weekday, time, referenceDate)
        },

        getNextOccurance(weekday, time, referenceDate = this.userStore.time) {
            const weekdayMap = { 'sun': 0, 'mon': 1, 'tue': 2, 'wed': 3, 'thu': 4, 'fri': 5, 'sat': 6 }
            const weekdayIndex = weekdayMap[weekday.toLowerCase()]
            const next = new Date(referenceDate)
            next.setDate(referenceDate.getDate() + ((weekdayIndex + 7 - referenceDate.getDay()) % 7))
            next.setHours(time.split(':')[0], time.split(':')[1], 0, 0)

            if (next < referenceDate)
                next.setDate(next.getDate() + 7)

            return next;
        },

        setDistances() {
            if (this.userStore.location) {
                this.pickupPoints.forEach(point => {
                    point.distance = this.getDistanceBetween(
                        this.userStore.location.coordinates.latitude,
                        this.userStore.location.coordinates.longitude,
                        point.acf.location.lat,
                        point.acf.location.lng
                    )
                })

                this.pickupPoints.sort((a, b) => a.distance - b.distance)
            }
        },

        getDistanceBetween(lat1, lon1, lat2, lon2) {
            if (!lat1 || !lat2 || !lon1 || !lon2) {
                return null;
            }

            var R = 6371; // Radius of the earth in km
            var dLat = (lat2 - lat1) * (Math.PI / 180);
            var dLon = (lon2 - lon1) * (Math.PI / 180)
            var a =
                Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) *
                Math.sin(dLon / 2) * Math.sin(dLon / 2)
            var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            var d = R * c; // Distance in km
            return d;
        },

        toggleSelector() {
            this.showSelector = !this.showSelector
        },

        search(search) {
            if (search === undefined)
                return this.pickupPoints

            if (search.length > 2) {
                return this.pickupPoints.filter(point => {
                    return point.title.rendered.toLowerCase().includes(search.toLowerCase()) ||
                        point.acf.location.street_name.toLowerCase().includes(search.toLowerCase()) ||
                        point.acf.location.city.toLowerCase().includes(search.toLowerCase()) ||
                        String(point.acf.location.post_code).includes(search);
                });
            } else {
                return this.pickupPoints
            }
        },
    }
})