<script>
export default {
    data() {
        return {
            codeHash: '80683db3a0732bc5edc60292803b08e787c9ae8d65f9cb4055bcc099ba1eb8f1',
            accessCode: null,
            showPassword: false,
        }
    },

    methods: {
        async handleAccessCode() {
            const encoder = new TextEncoder();
            const data = encoder.encode(this.accessCode);
            const hash = await window.crypto.subtle.digest('SHA-256', data);
            const hashString = Array.from(new Uint8Array(hash)).map(b => b.toString(16).padStart(2, '0')).join('')
            if (this.codeHash == hashString) {
                this.$cookies.set('accessCode', hashString, 60 * 60 * 24 * 7);
                this.$router.push('/')
            } else {
                this.$q.notify({
                    message: 'Zugangscode ist falsch!',
                    color: 'negative',
                    position: 'top',
                    timeout: 2000,
                })
            }
            
        }
    }
}
</script>

<template>
    <div class="container-sm q-pa-md">
        <div class="row">
            <div class="col text-center">
                <h1>Wichtiger Hinweis</h1>
                <p>
                    Diese App ist derzeit in Entwicklung! Diese Version dient nur als Vorschau für Entwicklungszwecke. Bestellungen werden weder beachtet noch bearbeitet.
                    Es werden Cookies von Drittanbietern gesetzt und Daten ohne weitere Zustimmung gesammelt und verarbeitet.
                </p>
                <QInput v-model="accessCode" label="Zugangscode" :type="showPassword ? '' : 'password'">
                    <template v-slot:append>
                        <q-icon :name="showPassword ? 'visibility_off' : 'visibility'"
                                class="cursor-pointer"
                                v-on:click="showPassword = !showPassword"></q-icon>
                    </template>
                </QInput>
                <QBtn @click="handleAccessCode" color="accent" text-color="darkGrey" class="q-mt-lg" label="Weiter" />
            </div>
        </div>
    </div>
</template>