<!-- eslint-disable vue/multi-word-component-names -->
<script>
import { mapStores } from 'pinia'
import { useUserStore } from '@/stores/user.js'
import { useOrderStore } from '@/stores/order.js'
import { useCartStore } from '@/stores/cart.js'

export default {
    data() {
        return {
            orders: null,
        }
    },

    computed: {
        ...mapStores(useUserStore, useOrderStore, useCartStore),
    },

    mounted() {
        this.orderStore.fetchOrders()
        this.$emit('content-loading')
        this.cartStore.initialized.then(() => {
            this.$emit('content-loaded')
        })
    },

    methods: {

    }
}
</script>
<template>
    <div class="q-pa-md container-sm">
        <RouterView v-slot="{ Component }">
            <Transition 
                enter-active-class="animate__animated animate__fadeInRight animate__faster animate__delay-1s" 
                leave-active-class="animate__animated animate__faster animate__fadeOutLeft"
            >
                <component :is="Component" />
            </Transition>
        </RouterView>
    </div>
</template>
<style>
    /*body.body--dark {
        background: var(--q-darkGrey) !important;
    }*/
</style>