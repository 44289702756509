<script>
import { mapStores } from 'pinia'
import { useUserStore } from './stores/user.js'
import { useProductStore } from './stores/product.js'
import { useCartStore } from './stores/cart.js'
import { usePickupPointStore } from './stores/pickupPoint.js'

import { routes } from './router.js'

import { createMetaMixin } from 'quasar'

import AppHeader from './components/App/AppHeader.vue';
import AppDrawer from './components/App/AppDrawer.vue';
import AppFooter from './components/App/AppFooter.vue';
import AppPreloader from './components/App/AppPreloader.vue';
import CookiesDialog from './dialogs/CookiesDialog.vue';

const metaData = {
    title: 'RegioMarkt',
    //titleTemplate: title => `RegioMarkt - ${title}`,
    meta: {
        description: {
            name: 'description',
            content: 'Dein Abholmarkt für regionale Produkte von Produzenten aus deiner Umgebung. Bestelle online und hole deine Produkte bei einer Abholstation in deiner Nähe ab.',
        },
        keywords: {
            name: 'keywords',
            content: 'RegioMarkt, Abholmarkt, regionale Produkte, Abholstation, Produzenten, Umgebung, online bestellen, abholen, Gemüse, Obst, Milchprodukte'
        },
        robots: {
            name: 'robots',
            content: 'index, follow'
        },
        googleTranslate: { name: 'google', content: 'notranslate' },
        equiv: { 'http-equiv': 'Content-Type', content: 'text/html; charset=UTF-8' },
    },

    noscript: {
        default: 'Diese Seite benötigt JavaScript um korrekt zu funktionieren.',
    }
}

export default {
    data() {
        return {
            dataLoading: true,
            contentLoading: false,
            loadingState: 'die App',
            showDialog: {
                location: false
            }
        }
    },

    components: {
        AppHeader,
        AppDrawer,
        AppFooter,
        AppPreloader
    },

    mixins: [createMetaMixin(metaData)],
    
    mounted() {
        this.initialize()
    },

    watch: {
        'this.userStore.user' : { handler() { 
            console.log("User changed: ", this.userStore.user)
            if (!this.userStore.isLoggedIn) this.$router.push('/start') 
            else this.$router.push('/')
        } }
    },

    computed: {
        ...mapStores(useUserStore, useProductStore, useCartStore, usePickupPointStore),
        
        showPreloader() {
            return this.dataLoading || this.contentLoading
        },

        showPage() {
            return ! this.dataLoading;
        },

        liveApiInDev() {
            return import.meta.env.DEV && import.meta.env.VITE_API_URL != 'http://regio-markt.local'
        }
    },

    methods: {
        // Get all data that is required before the app can be displayed
        async initialize() {
            const queryParams = new URLSearchParams(window.location.search)

            this.$q.addressbarColor.set('#08231C')
            
            this.loadingState = 'deine Benutzerdaten'
            await this.userStore.initialize()

            if (this.userStore.wpUser?.user_roles.includes('producer')) {
                routes.producer.forEach(route => {
                    this.$router.addRoute(route)
                })
                this.$router.replace(window.location.pathname)
            } else {
                routes.customer.forEach(route => {
                    this.$router.addRoute(route)
                })
                this.$router.replace(window.location.pathname)
                
                this.loadingState = 'die Abholstationen'
                await this.pickupPointStore.fetchPickupPoints();
                this.cartStore.fetchCart();
            }

            console.log(this.$route.fullPath)


            this.loadingState = 'den Seiteninhalt'

            if (!this.userStore.cookieAcceptance) {
                this.$q.dialog({
                    component: CookiesDialog,
                })
            }

            this.dataLoading = false
            
            if (queryParams.has('error') && queryParams.get('error') == 'access_denied') {
                console.log("Error: ", queryParams.get('error_description'))

                this.$q.notify({
                    message: queryParams.get('error_description'),
                    color: 'negative',
                    position: 'top',
                    icon: 'sym_r_person_cancel',
                    timeout: 10000,
                    actions: [
                        {label: 'Zurück zum Login', color: 'white', handler: this.userStore.login}
                    ]
                })
            }
            
            console.log("✅ App initialized")
        },

        setContentLoading() {
            console.log("Setting content is loading!")
            this.contentLoading = true
        },

        setContentLoaded() {
            console.log("Content loading arrived at parent!")
            this.contentLoading = false
        }
    }
}
</script>

<template>
    <TransitionGroup 
        enter-active-class="animate__animated animate__fadeIn animate__faster animate__delay-1s" 
        leave-active-class="animate_animated animate__faster animate__fadeOut"
    >
        <QChip v-if="liveApiInDev" class="fixed z-top" color="negative" size="18px" style="bottom: 20px; right: 20px;">
            <QIcon name="warning" rounded color="white" class="q-mr-md" />
            Live API in Development!
        </QChip>
        <AppPreloader v-if="showPreloader" :message="`Lade ${loadingState}`" />
        <QLayout view="hHh lpR fFf" v-if="showPage">
            <AppHeader />
            <AppDrawer />
            
            <QPageContainer>
                <RouterView v-slot="{ Component }">
                    <transition enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOut">
                        <component 
                            :is="Component" 
                            @content-loading="contentLoading = true" 
                            @content-loaded="contentLoading = false"
                            @show-location-dialog="showDialog.location = true"
                            @hide-location-dialog="showDialog.location = false"
                        />
                    </transition>
                </RouterView>
            </QPageContainer>
            
            <AppFooter />
        </QLayout>
    </TransitionGroup>
</template>

<style lang="scss">
    @use '@/sass/mixins';
    @use '@/sass/blocks';

    :root {
        --animate-duration: 300ms;
        --animate-delay: 300ms;
    }

    #app {
        overflow: hidden;
        position: relative;

        &::before,
        &::after {
            content: '';
            position: absolute;
            background: url('@/assets/leaf.svg') no-repeat;
            width: 379px;
            height: 249px;
            z-index: 0;
        }

        &::before {
            top: 655px;
            left: -158px;
            transform: rotate(125deg);
        }

        &::after {
            top: 1400px;
            right: -90px;
        }

        .q-layout {
            z-index: 1;
        }
    }

    html {
        background-color: #08231C;
    }

    body {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;

        &.body--dark {
            @include mixins.background-gradient;
        }
    }

    .container {
        max-width: 1300px;
        margin: 0 auto;
    }

    .container-sm {
        max-width: 500px !important;
        margin: 0 auto;
    }

    .q-dialog {
        z-index: 5000;

        .q-dialog__backdrop {
            background: rgba(0, 0, 0, 0.75);
        }

        .q-card {
            width: 100%;
            border: 2px solid var(--q-tertiary);
            border-radius: 8px;
            box-shadow: none;
            padding: 16px;

            .q-btn--rectangle {
                padding: 4px 16px;
            }
        }
    }

    .q-page-container {
        padding-bottom: 212px;
    }

    .q-menu {
        background: var(--q-white);
        border-radius: 0 0 8px 8px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

        .q-item {
            color: var(--q-darkGrey) !important;
            font-weight: 500;
            font-size: 16px;
            padding: 8px 16px;
            border-top: 1px solid var(--q-accent);

            &.q-item--active {
                background: var(--q-accentPale);
            }
        }
    }

    .q-btn {
        text-transform: none !important;
        font-size: 16px;
        font-weight: 500;
        min-height: 48px;

        &.q-btn--rectangle {
            border-radius: 8px;
        }
    }

    .q-field.q-input,
    form.q-form .q-field.q-select,
    .q-textarea {
        background: var(--q-white);
        border-radius: 8px;
        padding: 0 8px;
        font-size: 16px;
        max-width: 500px;
        border: 2px solid var(--q-accentLight);
        transition: color border 0.3s;

        &.q-field--focused {
            border-color: var(--q-accent);
        }

        &.q-field--readonly {
            background: rgba(212, 237, 159, 0.2);
            border: none;

            .q-field__control::before {
                display: none;
            }

            .q-field__native, 
            .q-field__prefix,
            .q-field__suffix,
            .q-field__input,
            .q-field__label,
            .q-field__marginal,
            .q-field__bottom {
                color: var(--q-accentLight);
            }
        }

        .q-field__native, 
        .q-field__prefix,
        .q-field__suffix,
        .q-field__input,
        .q-field__label,
        .q-field__marginal,
        .q-field__bottom {
            color: var(--q-darkGrey);
        }

        .q-field__control::after {
            display: none;
        }

        .q-field__bottom .q-field__messages {
            color: var(--q-white);
        }

        .q-field__label {
            color: var(--q-grey) !important;
        }
    }

    .q-field.q-select {
        &.q-field--outlined {
            .q-field__control::before {
                border: 1px solid var(--q-accent);
                border-radius: 8px;
            }

        }
        
        .q-field__prepend,
        .q-field__append {
            color: var(--q-accent)
        }

        .q-field__native {
            color: inherit;
            font-weight: inherit;
        }
    }

    .q-toggle {
        .q-toggle__inner {
            width: 1.6em;
            height: 1.25em;

            .q-toggle__track {
                width: 100%;
                height: 100%;
                border-radius: 10em;
                opacity: 1;
            }

            .q-toggle__thumb {
                top: 50%;
                left: calc(0.3em + 3px);
                transform: translate(0, -50%);

                i.q-icon {
                    color: var(--q-accent);
                }

                &::after {
                    color: var(--q-primary);
                    background: var(--q-primary);
                }
            }

            &.q-toggle__inner--truthy {
                .q-toggle__thumb {
                    left: calc(0.8em - 3px);
                }
            }
        }
    }

    .q-card {
        background: linear-gradient(60deg, var(--q-primary) 0%, #030e0b 100%);
        border-radius: 8px;

        &.q-card--bordered {
            border: 2px solid var(--q-tertiary);
            box-shadow: none !important;
        }
    }

    .q-stepper {
        background: transparent !important;

        .q-stepper__header {
            max-width: 225px;
            margin: auto;
            
            .q-stepper__dot {
                width: 12px;
                height: 12px;
                min-width: unset;
                background: var(--q-primary);
                border: 2px solid var(--q-accentLight);
                transition: 0.3s;

                &::before, &::after {
                    background: var(--q-accentLight);
                    height: 1px;
                    top: calc(50% - 0.5px);
                    transition: color 0.3s;
                }

                &::before {right: 0;}
                &::after {left: 0;}

                span {
                    display: none;
                }
            }

            .q-stepper__tab--done {
                .q-stepper__dot {
                    background: var(--q-accent);
                    border-color: var(--q-accent);

                    &::before, &::after {
                        height: 3px;
                        top: calc(50% - 1.5px);
                        background: var(--q-accent);
                    }
                }
            }

            .q-stepper__tab--active {
                .q-stepper__dot {
                    height: 16px;
                    width: 16px;
                    background-color: var(--q-accent);
                    border-color: var(--q-accent);

                    &::before {
                        height: 3px;
                        top: calc(50% - 1.5px);
                        background: var(--q-accent);
                    }

                    &::after {
                        height: 1px;
                        top: calc(50% - 0.5px);
                        background: var(--q-accentLight);
                    }
                }

                &:first-child .q-stepper__dot {
                    height: 16px;
                    width: 16px;
                }
            }
        }
    }

    .q-pagination {
        .q-pagination__content {
            .q-btn.q-btn--outline {
                &::before {
                    border: 2px solid var(--q-accent);
                    border-radius: 8px;
                    background-color: var(--q-secondary);
                }
            }
        }
    }

    hr.q-separator {
        border-radius: 100px;

        &.dashed {
            
            background-color: transparent !important;
            height: 4px;
            background-image: url('@/assets/dotted_line.svg');
            background-size: auto 100%;
            background-repeat: repeat-x;
        }
    }

    a {
        text-decoration: underline;
        font-weight: 500;
        color: var(--q-accent);
    }

    p {
        hyphens: auto;
        word-wrap: break-word;
        overflow-wrap: break-word;
    }

    h1, h2, h3, h4, h5, h6,
    .text-h1, .text-h2, .text-h3, .text-h4, .text-h5, .text-h6 {
        font-family: 'Red Hat Display';
        font-weight: 700;
        line-height: 1.5;
    }

    h1, .text-h1 {
        font-size: 32px;
    }

    h2, .text-h2 {
        font-size: 28px;
    }

    h3, .text-h3 {
        font-size: 20px;
    }

    h4, .text-h4 {
        font-size: 16px;
    }

    h5, .text-h5 {
        font-size: 14px;
    }

    .text-micro {
        font-size: 12px;
    }

    .text-underline {
        text-decoration: underline;
        cursor: pointer;
    }

    .bg-tertiary {
        background-color: var(--q-tertiary) !important;
    }
    .text-tertiary {
        color: var(--q-tertiary) !important;
    }

    .bg-accentLight {
        background-color: var(--q-accentLight) !important;
    }
    .text-accentLight {
        color: var(--q-accentLight) !important;
    }

    .bg-accentPale {
        background-color: var(--q-accentPale) !important;
    }
    .text-accentPale {
        color: var(--q-accentPale) !important;
    }

    .bg-grey {
        background-color: var(--q-grey) !important;
    }
    .text-grey {
        color: var(--q-grey) !important;
    }

    .bg-darkGrey {
        background-color: var(--q-darkGrey) !important;
    }
    .text-darkGrey {
        color: var(--q-darkGrey) !important;
    }

    .bg-lightGrey {
        background-color: var(--q-lightGrey) !important;
    }
    .text-lightGrey {
        color: var(--q-lightGrey) !important;
    }

    .bg-white {
        background-color: var(--q-white) !important;
    }
    .text-white {
        color: var(--q-white) !important;
    }

    @font-face {
        font-family: Montserrat;
        src: url(@/assets/fonts/Montserrat/Montserrat-VariableFont_wght.ttf);
    }

    @font-face {
        font-family: 'Red Hat Display';
        src: url(@/assets/fonts/Red_Hat_Display/RedHatDisplay-VariableFont_wght.ttf);
    }
</style>

<style lang="scss" id="responsive">
    @media (max-width: --q-var-breakpoint-md-max) {
        h1, .text-h1 {font-size: 40px;}
        h2, .text-h2 {font-size: 32px;}
        h3, .text-h3 {font-size: 28px;}
        h4, .text-h4 {font-size: 20px;}
        h5, .text-h5 {font-size: 16px;}
    }
</style>