<script>
import QuantityStepper from '@/components/QuantityStepper.vue';

import { mapStores } from 'pinia'
import { useProductStore } from '@/stores/product'
import { useCartStore } from '@/stores/cart'

export default {
    data() {
        return {
            removeLock: false,
            productItem: null,
            loading: false,
            quantity: 0,
        }
    },

    props: {
        cartItem: Object,
        disableActions: Boolean,
    },

    components: {
        QuantityStepper,
    },

    mounted() {

    },

    computed: {
        ...mapStores(useProductStore, useCartStore),

        minimumQuantity() {
            return Number(this.cartItem.acf.measure.min_quantity != '' ? this.cartItem.acf.measure.min_quantity : this.cartItem.acf.measure.increment);
        },

        subtotal() {
            console.log(this.cartItem)
            let price = this.cartItem.price ? Number(this.cartItem.price) : Math.round(this.cartItem.totals.total * 100 + this.cartItem.totals.tax * 100)
            const subtotal = price * Number(this.cartItem.quantity.value);
            return this.cartStore.getFormattedPrice(subtotal);
        },
    },

    methods: {
        updateQuantity(quantity) {
            this.loading = true;
            console.log(this.cartItem.item_key, quantity)
            this.cartStore.updateQuantity(this.cartItem.item_key, quantity).then(() => {
                this.loading = false;
            });
        },

        removeFromCart() {
            if (this.removeLock) return;
            this.loading = true;
            this.cartStore.removeFromCart(this.cartItem.item_key).then(() => {
                this.loading = false;
            });
        },
    }

    
}
</script>

<template>
    <div class="cart-item q-py-md">
        <div class="row q-gutter-md">
            <div class="col-3">
                <QImg v-if="cartItem.featured_image" class="product-image" :src="cartItem.featured_image" :ratio="5/6" height="100%" />
                <div v-else class="bg-accentPale flex justify-center content-center q-pa-md" style="border-radius: 10px;">
                    <img src="@/assets/logo.png" style="width: 80px; height: 80px;" />
                </div>
            </div>
            <div class="col column justify-between q-py-md">
                <div class="column">
                    <div class="text-bold">{{ cartItem.name }}</div>
                    <div class="text-micro">
                        {{ cartStore.getFormattedPrice(cartItem.price) }} / {{ cartItem.acf.measure.increment }} {{ cartItem.acf.measure.unit }}
                    </div>
                </div>
                <div v-if="disableActions" class="text-bold">
                    {{ Number(cartItem.quantity.value) * Number(cartItem.acf.measure.increment) }} {{ cartItem.acf.measure.unit }}
                </div>
                <div v-else class="full-width q-mt-sm">
                    <QuantityStepper
                        :initQuantity="Number(cartItem.quantity.value)"
                        :increment="Number(cartItem.acf.measure.increment)"
                        :minQuantity="Number(minimumQuantity)"
                        :maxQuantity="Number(cartItem.quantity.max_purchase)"
                        :unit="cartItem.acf.measure.unit"
                        :loading="cartStore.loading"
                        dense
                        hide-add-to-cart
                        @quantity-change="updateQuantity"
                        @remove-from-cart="removeFromCart"
                    />
                </div>
            </div>
            <div class="col-2 column justify-center text-right">
                {{ subtotal }}
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .cart-item {
        .product-image {
            border-radius: 8px;
        }

        .col:not(.col-grow) {
            flex-basis: 0px;
            flex-grow: 1;
        }
    }
</style>