<script>
import CartItem from './CartItem.vue'
import CartTotals from './CartTotals.vue'

import { mapStores } from 'pinia'
import { useCartStore } from '@/stores/cart'
import { useUserStore } from '@/stores/user'

export default {
    data() {
        return {
            checkoutStep: 1,
            loading: false,
            redirectUri: window.location.origin + '/checkout/user',
        }
    },

    props: {
        disableActions: Boolean,
    },

    emits: ['done'],

    components: {
        CartItem,
        CartTotals
    },

    computed: {
        ...mapStores(useCartStore, useUserStore),
    },

    methods: {
        getSubtotalWithTax() {
            return this.cartStore.getFormattedPrice(String(Number(this.cartStore.cart.totals.subtotal) + Number(this.cartStore.cart.totals.subtotal_tax)));
        },
    }
}
</script>

<template>
    <div>
        <Transition 
            enter-active-class="animate__animated animate__fadeInLeft animate__faster animate__delay-1s" 
            leave-active-class="animate__animated animate__faster animate__fadeOutRight"
        >
            <div v-if="cartStore.isEmpty && !cartStore.loading">
                <div class="text-h4">Dein Warenkorb ist derzeit leer!</div><div>Gehe zum <RouterLink to="/">Shop</RouterLink> um Produkte hinzuzufügen.</div>
            </div>
            <div v-else-if="cartStore.isEmpty && cartStore.loading">
                <div class="q-py-md" v-for="i in 3" :key="i">
                    <div class="row q-gutter-md">
                        <div class="col">
                            <QSkeleton type="rect" height="108px" width="83%" />
                        </div>
                        <div class="col-grow">
                            <div class="full-height column justify-center">
                                <div><QSkeleton type="text" width="80px" height="14px" /></div>
                                <div><QSkeleton type="text" width="120px" height="12px" /></div>
                                <div class="full-width q-mt-sm" v-if="!disableActions"><QSkeleton type="QBtn" width="100px" height="24px" /></div>
                            </div>
                        </div>
                        <div class="col column justify-center text-right"><QSkeleton type="text" width="35px" height="18px" /></div>
                    </div>
                </div>
            </div>
            <div v-else>
                <div>
                    <CartItem v-for="cartItem in cartStore.cart.items" :key="cartItem.id" :cartItem="cartItem" :disable-actions="disableActions" />
                </div>
                <div class="totals q-my-lg" v-if="!cartStore.isEmpty">
                    <CartTotals :totals="cartStore.cart.totals" :fees="cartStore.cart.fees" :coupons="cartStore.cart.coupons" />
                    <Transition appear enter-active-class="animate__animated animate__fadeInDown" leave-active-class="animate__animated animate__fadeOutUp">
                        <div class="row items-center no-wrap q-pa-md q-my-lg bg-accentLight" v-if="cartStore.cart.fees.bearbeitungsgebuehr">
                            <QIcon name="shopping_basket" color="darkGrey" size="24px" />
                            <div class="q-px-md text-darkGrey">
                                Noch <span class="text-bold">{{ cartStore.getFormattedPrice(1500 - cartStore.cart.totals.subtotal) }}</span>
                                bis zur Gratis Bearbeitung
                            </div>
                        </div>
                    </Transition>
                    <div class="row items-center no-wrap q-pa-md">
                        <QIcon name="info" color="accentLight" size="20px" />
                        <div class="q-px-md">Aufgrund der Individualität der Natur, kann das effektive Gewicht um 5% schwanken.</div>
                    </div>
                </div>
            </div>
        </Transition>
    </div>
</template>

<style lang="scss" scoped>

</style>