<script>
export default {
    data() {
        return {

        }
    },

    emits: ['ok', 'hide'],

    computed: {
        top() {
            let top = '140px'
            if (this.$q.platform.is.chrome)
                top = '140px'
            else if (this.$q.platform.is.firefox)
                top = '133px'
            else if (this.$q.platform.is.edge)
                top = '116px'

            return top
        },

        left() {
            let left = '450px'
            if (this.$q.platform.is.chrome)
                left = '422px'
            else if (this.$q.platform.is.firefox)
                left = '662px'
            else if (this.$q.platform.is.edge)
                left = '381px'
            return left
        },
        
        rotate() {
            let rotate = 'rotate(225deg)'
            if (this.$q.platform.is.chrome)
                rotate = 'rotate(225deg)'
            else if (this.$q.platform.is.firefox)
                rotate = 'rotate(230deg)'
            else if (this.$q.platform.is.edge)
                rotate = 'rotate(225deg)'
            return rotate
        },

        display() {
            return this.$q.platform.is.desktop ? 'block' : 'none'
        },

    },
    
    methods: {
        show() {
            this.$refs.dialog.show()
        },

        hide() {
            this.$refs.dialog.hide()
        },

        onDialogHide() {
            this.$emit('hide') 
        },

        onOkClick() {
            this.$emit('ok')
            this.hide()
        },

        onCancelClick() {
            this.hide()
        },
    }
}
</script>

<template>
    <QDialog ref="dialog" @hide="onDialogHide">
        <img src="@/assets/Arrow.svg" width="180" class="absolute" :style="{top, left, transform: rotate, display}" />
        <div class="cover flex text-center">
            <QIcon name="sym_r_person_pin_circle" size="80px" class="text-white q-mx-auto" />
            <h1 class="text-h1 text-white">Bitte aktiviere die Standortbestimmung, um fortzufahren.</h1>
            <div class="text-underline full-width" @click="hide">Schließen</div>
        </div>
    </QDialog>
</template>