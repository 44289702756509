<script>
export default {
    data() {
        return {
            imageDimensions: {
                width: 0,
                height: 0
            }
        }
    },

    props: {
        producer: {
            type: Object,
            required: true
        }
    },

    computed: {
        imageClass() {
            const { width, height } = this.imageDimensions;
            if (width > height) {
                return 'landscape';
            } else {
                return 'portrait';
            }
        }
    },

    methods: {
        getImageDimensions() {
            const img = this.$refs.producerImage.$el.querySelector('img');
            if (img) {
                console.log(img)
                this.imageDimensions.width = img.naturalWidth;
                this.imageDimensions.height = img.naturalHeight;
            }
        }
    }
}
</script>

<template>
    <QCard bordered @click="$router.push(`/producers/${producer.id}`)">
        <div class="overflow-hidden bg-accentLight">
            <q-responsive :ratio="16/9">
                <QImg 
                    v-if="producer.image_url" 
                    :src="producer.image_url" 
                    class="q-pa-md producer-image"
                    :class="imageClass" 
                    ref="producerImage" 
                    alt="Producer Image"
                    @load="getImageDimensions"
                />
                <div v-else class="bg-accentPale flex justify-center content-center">
                    <img src="@/assets/logo.png" style="width: 120px; height: 120px;" />
                </div>
            </q-responsive>
        </div>
        <QCardSection class="text-center">
            <div class="producer-name text-h3 text-center">{{ producer.name }}</div>
            <div class="text-center">{{ producer.address?.zip }} {{ producer.address?.city }}</div>
            <QSeparator class="dashed q-my-md q-mx-auto" width="150px" />
            <div class="text-center">
                {{ producer.description.substring(0, 200) }}{{ producer.description.length > 200 ? '...' : '' }}
            </div>
            <div class="text-center q-mt-lg">
                <a :href="producer.website" target="_blank" class="text-accent producer-link">{{ producer.website }}</a>
            </div>
        </QCardSection>
    </QCard>
</template>

<style lang="scss">
    .producer-image {
        &.portrait .q-img__image {
            object-fit: contain !important;
        }
    }

    .producer-link {
        word-wrap: break-word;
    }
</style>