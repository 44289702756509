import { createWebHistory, createWebHashHistory, createRouter } from 'vue-router'
import { useUserStore } from './stores/user.js'
import { authGuard } from '@auth0/auth0-vue'
import { Notify } from 'quasar'

import DevDisclaimer from './pages/DevDisclaimer.vue'
import Visitor from './pages/Visitor.vue'
import Shop from './pages/Shop.vue'
import Checkout from './pages/Checkout.vue'
import Profile from './pages/Profile.vue'
import Producers from './pages/Producers.vue'
import SingleProducer from './pages/SingleProducer.vue'
import ProfileDashboard from './components/Profile/ProfileDashboard.vue'
import ProfileOrders from './components/Profile/ProfileOrders.vue'
import ProfileSettings from './components/Profile/ProfileSettings.vue'
import ProfileOrderItem from './components/Profile/ProfileOrderItem.vue'
import Imprint from './pages/Imprint.vue'
import CMSPage from './components/Globals/CMSPage.vue'
import NotFound from './pages/NotFound.vue'

const routes = {
    generic: [
        { path: '/disclaimer', component: DevDisclaimer },
        { path: '/start', component: Visitor, meta: { requiresAuth: false, requiresPickupPoint: false } },
        { path: '/about', component: CMSPage, props: { slug: 'about-us', containerized: true } },
        { path: '/producers', component: Producers },
        { path: '/producers/:producerId', component: SingleProducer },
        { path: '/imprint', component: Imprint },
        { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound},
    ],

    customer: [
        { path: '/', component: Shop, meta: { requiresAuth: false, requiresPickupPoint: true } },
        { path: '/checkout', redirect: '/checkout/cart' },
        { path: '/checkout/:step', component: Checkout, meta: { requiresAuth: false, requiresPickupPoint: true } },
        { path: '/profile', component: Profile, beforeEnter: authGuard, children: [
            {
                path: '',
                component: ProfileDashboard
            },
            {
                path: 'orders',
                component: ProfileOrders,
            },
            {
                path: 'orders/:orderId',
                component: ProfileOrderItem,
            },
            {
                path: 'settings',
                component: ProfileSettings
            }
        ] },
    ],
    
    producer: [
        { path: '/', component: () => import('@/pages/ProducerProducts.vue') },
        { path: '/profile', component: () => import('@/pages/ProducerProfile.vue'), beforeEnter: authGuard, children: [
            {
                path: '',
                component: () => import('@/components/Producer/ProducerProfileDashboard.vue')
            },
            {
                path: 'settings',
                component: ProfileSettings
            }
        ] },
        { path: '/products/add', redirect: '/products/add/info' },
        { path: '/products/add/:step', component: () => import('@/components/Producer/ProducerAddProduct.vue'), },
        { path: '/products/:productId', redirect: to => ({ path: `/products/${to.params.productId}/info` }) },
        { path: '/products/:productId/:step', component: () => import('@/components/Producer/ProducerEditProduct.vue'), beforeEnter: authGuard }
    ]
}

const base = window.location.hostname === 'sebastianlamprecht.github.io' ? '/regiomarkt-app/#/' : '/'

const routerConf = {
    history: createWebHistory(base),
    routes: routes.generic,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                el: to.hash,
                behavior: 'smooth'
            }
        } else if (savedPosition) {
            return savedPosition
        } else {
            return { top: 0 }
        }
    }
}

if (window.location.hostname === 'sebastianlamprecht.github.io') {
    routerConf.history = createWebHashHistory(base)
}

const router = createRouter(routerConf)

router.beforeEach(async (to, from, next) => {
    const userStore = useUserStore()

    await userStore.inizialized // Wait for user to be loaded

    if (to.query.payment_intent && to.path !== '/checkout/confirmation' && to.path !== '/checkout/payment') {
        if (to.query.redirect_status === 'succeeded') {
            next('/checkout/confirmation' + to.fullPath.substring(to.fullPath.indexOf('?')))
            return
        } else {
            next('/checkout/payment')
            Notify({
                message: 'Die Zahlung konnte nicht abgeschlossen werden. Bitte versuchen Sie es erneut.',
                color: 'negative',
                position: 'top-right',
                timeout: 5000
            })
        }
    }

    if (to.meta.requiresAuth === undefined || to.meta.requiresPickupPoint === undefined) {
        next()
        return
    }

    if (to.meta.requiresAuth && ! userStore.isAuthenticated) {
        next(from.path)
        return
    }

    if (to.meta.requiresPickupPoint && ! userStore.hasPickupPoint) {
        next('/start')
        return
    }

    if (userStore.hasPickupPoint && to.path === '/start') {
        next('/')
        return
    }

    next()
})

export default router
export { routes }
export { base }