import { defineStore } from 'pinia'
import { Notify } from 'quasar'
import { useUserStore } from './user'
import router from '@/router'
import VueCookies from 'vue-cookies'
import axios from 'axios'

let resolveCartInit

/**
 * User store
 *
 * @type {PiniaStore}
 */
export const useCartStore = defineStore('cart', {
    state: () => {
        return {
            loading: false,
            initialized: new Promise(resolve => {
                resolveCartInit = resolve
            }),
            cart: {},
            cartKey: VueCookies.get('cart_key'),
            userStore: useUserStore()
        }
    },

    getters: {
        itemCount() {
            return this.cart.items?.length ?? 0;
        },

        isEmpty() {
            return this.itemCount === 0;
        }
    },

    actions: {
        /**
         * Fetches the cart from the server
         *
         * @async
         * @returns {Promise<Object>}
         */
        async fetchCart() {
            this.loading = true

            let headers = {
                'Content-Type': 'application/json',
            }

            if (this.userStore.isAuthenticated)
                headers['Authorization'] = `Bearer ${this.userStore.token}`

            return axios.get(`${import.meta.env.VITE_API_URL}/wp-json/cocart/v2/cart${this.cartKey ? '?cart_key=' + this.cartKey : ''}`, {
                headers: headers,
            })
            .then(response => {
                this.setCart(response.data)

                if (! this.userStore.isAuthenticated) {
                    const cartKey = response.headers['cocart-api-cart-key'] || response.data.cart_key

                    if (cartKey) {
                        this.cartKey = cartKey
                        if (! VueCookies.isKey('cart_key'))
                            VueCookies.set('cart_key', cartKey, '7d')
                    } else {
                        console.error("No cart key in response")
                        console.log(response)
                    }
                } else {
                    if (VueCookies.isKey('cart_key'))
                        VueCookies.remove('cart_key')

                    this.cartKey = null
                }

                console.log("Fetched cart:", response.data)

                resolveCartInit()

                this.loading = false
            })
        },

        /**
         * Makes a request to the server to add a product to the cart
         *
         * @async
         * @param {Number} product_id
         * @param {Number} [variation=null]
         * @returns {Promise<Objec>}
         */
        async addToCart(product_id, variation = null) {
            this.loading = true;

            let headers = {
                'Content-Type': 'application/json',
            }

            let params = {}

            if (this.userStore.isAuthenticated) {
                headers['Authorization'] = `Bearer ${this.userStore.token}`
            } else {
                params.cart_key = this.cartKey
            }

            let data = {
                id: product_id.toString(),
            }

            if (variation)
                data.variation_id = variation

            console.log('Adding to cart:', data)

            return axios.post(`${import.meta.env.VITE_API_URL}/wp-json/cocart/v2/cart/add-item`, data, {
                headers: headers,
                params: params,
            })
            .then(response => {
                this.setCart(response.data)
                Notify.create({
                    message: 'Produkt hinzugefügt',
                    color: 'positive',
                    icon: 'check',
                    position: 'top',
                    actions: [{ label: 'Zum Warenkorb', color: 'white', handler: () => router.push('/checkout/cart') }]
                })
                return response.data
            }).catch(error => {
                console.error('Error adding to cart:', error)
                Notify.create({
                    message: 'Fehler beim Hinzufügen zum Warenkorb',
                    color: 'negative',
                    icon: 'warning',
                    position: 'top'
                })
                this.fetchCart()
            }).finally(() => {
                this.loading = false
            })
        },

        /**
         * Changes the quantity in cart of a given cart item
         *
         * @async
         * @param {String} key
         * @param {Number} quantity
         * @returns {Promise<Void>}
         */
        async updateQuantity(key, quantity) {
            this.loading = true

            let headers = {
                'Content-Type': 'application/json',
            }

            let params = {}

            if (this.userStore.isAuthenticated) {
                headers['Authorization'] = `Bearer ${this.userStore.token}`
            } else {
                params.cart_key = this.cartKey
            }

            return axios.post(`${import.meta.env.VITE_API_URL}/wp-json/cocart/v2/cart/item/` + key, JSON.stringify({
                quantity: String(quantity),
                return_cart: true,
            }), {
                headers: headers,
                params: params,
            }).then(response => {
                Notify.create({
                    message: 'Warenkorb aktualisiert',
                    color: 'positive',
                    icon: 'check',
                    position: 'top',
                    actions: [{ label: 'Zum Warenkorb', color: 'white', handler: () => router.push('/checkout/cart') }]
                })
                this.setCart(response.data)
            }).catch(error => {
                console.error('Error updating cart:', error)
                Notify.create({
                    message: 'Fehler beim Aktualisieren des Warenkorbs',
                    color: 'negative',
                    icon: 'warning',
                    position: 'top'
                })
                this.fetchCart()
            }).finally(() => {
                this.loading = false
            })
        },
        
        /**
         * Removes a given cart item from the cart
         *
         * @async
         * @param {String} key
         * @returns {Promise<Void>}
         */
        async removeFromCart(key) {
            this.loading = true

            let headers = {
                'Content-Type': 'application/json',
            }

            let params = {}

            if (this.userStore.isAuthenticated) {
                headers['Authorization'] = `Bearer ${this.userStore.token}`
            } else {
                params.cart_key = this.cartKey
            }

            return axios.delete(`${import.meta.env.VITE_API_URL}/wp-json/cocart/v2/cart/item/` + key, {
                headers: headers,
                params: params,
            }).then(response => {
                this.setCart(response.data)
            }).catch(error => {
                console.error('Error removing from cart:', error)
                Notify.create({
                    message: 'Fehler beim Löschen aus dem Warenkorb',
                    color: 'negative',
                    icon: 'warning',
                    position: 'top'
                })
                this.fetchCart()
            }).finally(() => {
                this.loading = false
            })
        },

        /**
         * Setter for the cart object
         *
         * @param {Object} cart
         */
        setCart(cart) {
            this.cart = cart
        },

        /**
         * Setter for the cart items
         *
         * @param {Object} cartItem
         */
        setCartItem(cartItem) {
            this.cart.items = {
                ...this.cart.items,
                [cartItem.key]: cartItem
            }
        },

        /**
         * Takes a string in the format "1000" and returns "10,00 €"
         *
         * @param {String} price
         * @returns {String}
         */
        getFormattedPrice(price) {
            if (price < 1)
                return `0${this.cart?.currency?.currency_decimal_separator??','}00${this.cart?.currency?.currency_symbol??'€'}`
            let priceString = price.toString()
            return (priceString.length === 2 ? '0' + priceString : priceString).slice(0, -2) + (this.cart?.currency?.currency_decimal_separator??',') + priceString.slice(-2) + (this.cart?.currency?.currency_symbol??'€');
        },

        /**
         * Getter function for a cart item by product_id
         *
         * @param {Number} product_id
         * @returns {Object|null}
         */
        getCartItem(product_id) {
            return this.cart.items ? this.cart.items.find(item => item.id === product_id) : null
        },

        
        /**
         * Ends the session by removing all cookies
         */
        endSession() {
            console.warn("Ending cocart session")
            this.cart = {}

            VueCookies.remove('cart_key')
        },

        
        /**
         * Applies coupon to the cart via the cocart pro API
         *
         * @async
         * @param {String} code
         * @returns {Promise<Object>}
         */
        async pushCoupon(code) {
            let headers = {
                'Content-Type': 'application/json',
            }
            
            let params = {
                coupon: code
            }

            if (!this.userStore.isAuthenticated) {
                params.cart_key = this.cartKey
            } else {
                params.cart_key = this.userStore.wpUser.id
                headers['Authorization'] = `Bearer ${this.userStore.token}`
            }

            return axios.post(`${import.meta.env.VITE_API_URL}/wp-json/cocart/v1/coupon`, params, { headers })
        },

        
        /**
         * Removes a coupon from the cart via the cocart pro API
         *
         * @async
         * @param {String} code
         * @returns {Prmoise<Object>}
         */
        async removeCoupon(code) {
            let headers = {
                'Content-Type': 'application/json',
            }

            let data = {
                coupon: code
            }

            let params = {}

            if (!this.userStore.isAuthenticated) {
                params.cart_key = this.cartKey
            } else {
                headers['Authorization'] = `Bearer ${this.userStore.token}`
            }            
            
            return axios.delete(`${import.meta.env.VITE_API_URL}/wp-json/cocart/v1/coupon`, {
                headers,
                params,
                data
            })
        },

        /**
         * Login function for cocart
         * 
         * @todo: Check if it is acutally required
         *
         * @async
         * @returns {Boolean|Void}
         */
        async login() {
            if (! this.userStore.isAuthenticated)
                return false

            this.loading = true
            await axios.post(`${import.meta.env.VITE_API_URL}/wp-json/cocart/v2/login`, {
                headers: {
                    Authorization: `Bearer ${this.userStore.token}`
                }
            }).finally(() => {
                this.loading = false
            })


        },
        
        /**
         * Logout function for cocart - destroys the session
         *
         * @async
         * @returns {Promise<Void>}
         */
        async logout() {
            /*this.loading = true
            return axios.post(`${import.meta.env.VITE_API_URL}/wp-json/cocart/v2/logout`, {}, 
            {
                withCredentials: true,
            }).finally(() => {
                this.loading = false
            }).catch(error => {
                console.log(error)
            })*/

            VueCookies.remove('cart_key')
        }
    }
})