<!-- eslint-disable vue/multi-word-component-names -->
<script>
import CMSPage from '@/components/Globals/CMSPage.vue'
import CookiesDialog from '@/dialogs/CookiesDialog.vue'

export default {
    components: {
        CMSPage
    },

    methods: {
        openCookiesDialog() {
            this.$q.dialog({
                component: CookiesDialog
            })
        }
    }
}
</script>

<template>
    <div class="container-sm q-py-lg q-px-md">
        <h2 class="text-h2">Einstellungen zu Datenschutz</h2>
        <QBtn label="Cookie Einstellungen verwalten" outline color="accent" @click="openCookiesDialog" />
        <CMSPage slug="impressum-datenschutz" @content-loading="$emit('content-loading')" @content-loaded="$emit('content-loaded')" />
    </div>
</template>