<script>
export default {
    props: {
        title: String,
        icon: String,
        disabled: Boolean,
        hideIcon: Boolean,
        loading: Boolean
    }
}
</script>

<template>
    <div class="row q-pa-md cursor-pointer no-wrap" :style="{opacity: disabled ? 0.6 : 1}" style="word-break: break-word;">
        <QInnerLoading :showing="loading" />
        <slot name="prepend" />
        <div class="col-grow q-px-md">
            <div class="text-bold text-accent q-mb-sm">{{ title }}</div>
            <slot />
        </div>
        <div class="column justify-center">
            <slot name="append" />
            <QIcon :name="icon ? icon : 'arrow_forward_ios'" v-if="!hideIcon" size="24px" color="accent" />
        </div>
    </div>
</template>