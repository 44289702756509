<script>
import { mapStores } from 'pinia'
import { useCartStore } from '@/stores/cart'

import { debounce } from 'quasar'

export default {
    data() {
        return {
            quantity: 0,
            removeLock: false,
        }
    },

    props: {
        initQuantity: {
            type: Number,
            default() {
                return 0
            }
        },
        increment: {
            type: Number,
            default() {
                return 1
            }
        },
        minQuantity: Number,
        maxQuantity: Number,
        unit: String,
        hideAddButton: Boolean,
        dense: Boolean,
        label: String,
        loading: Boolean,
        disabled: Boolean
    },

    emits: ['quantity-change', 'add-to-cart', 'remove-from-cart'],

    watch: {
        // Do this rather than setting it once in lifecycle hook
        // because the the cart might change after the quantity has been set
        initQuantity: {
            immediate: true,
            handler() {
                this.quantity = Number(this.initQuantity) * this.increment;
            }
        },

        'cartStore.cart': {
            handler() {
                this.quantity = Number(this.initQuantity) * this.increment;
            }
        },

        /*'cartStore.loading': {
            handler(newValue) {
                if (this.internalLoading && !newValue) {
                    this.internalLoading = false;
                }
            }
        },

        loading: {
            handler(newValue) {
                if (this.internalLoading && !newValue) {
                    this.internalLoading = false
                }
            }
        }*/
    },

    mounted() {
        this.handleQuantityChange = debounce(this.handleQuantityChange, 40);
    },

    computed: {
        ...mapStores(useCartStore),

        displayedQuantity() {
            return this.quantity * this.increment;
        },
    },
    
    methods: {
        addToCart() {
            this.quantity = 1;
            //this.internalLoading = true;
            this.$emit('add-to-cart');
        },

        handleQuantityChange(newQuantity) {
            console.log('new quantity', newQuantity)
            if (newQuantity === 0 && !this.removeLock) {
                console.log('removing from cart')
                this.$emit('remove-from-cart')
                this.quantity = 0
                return;
            }

            this.quantity = newQuantity;
            let trackQuantity = this.quantity;
            this.removeLock = true;

            setTimeout(() => {
                if (this.quantity === trackQuantity) {
                    const remainder = this.quantity % this.increment;
                    if (remainder !== 0) {
                        this.quantity = this.quantity - remainder + this.increment;
                    }

                    if (this.minQuantity && this.quantity < this.minQuantity) {
                        this.quantity = this.minQuantity;
                    }

                    this.$emit('quantity-change', this.quantity / this.increment);
                    this.removeLock = false;
                }
            }, 1500);
        },
    },
}
</script>

<template>
    <div class="relative-position" :class="disabled ? 'disabled' : ''">
        <QInnerLoading
            :showing="loading"
            color="accentLight"
            size="24px"
            style="z-index: 9; border-radius: 8px;"
        />
        <QBtn 
            v-if="quantity == 0 && !hideAddButton" 
            :label="label ?? 'In den Warenkorb'"
            color="accent"
            text-color="black"
            class="full-width"
            :disabled="disabled"
            @click="addToCart" 
        />
        <div class="row items-center no-wrap full-width quantity-stepper" :class="dense ? 'dense' : ''"
            :style="{'background-color': dense ? 'transparent' : 'white', 'color': dense ? 'white' : 'black', 'border': dense ? '1px solid accent' : 'none'}" v-else>
            <QBtn
                v-if="!hideAddButton || quantity > 0"
                class="quantity-stepper-button"
                :flat="dense"
                :size="dense ? 'sm' : '18px'"
                :icon="quantity > 1 ? 'remove' : 'delete'"
                :color="dense ? 'transparent' : 'accent'"
                :text-color="dense ? 'accent' : 'black'"
                :disabled="quantity <= 1 && removeLock || disabled"
                @click="handleQuantityChange(quantity - increment)"
            />
            <QInput
                class="quantity-input"
                :class="dense ? 'dense' : ''"
                input-class="text-center"
                :model-value="quantity"
                :dense="dense"
                :style="{'background-color': dense ? 'transparent' : 'var(--q-white)'}"
                :suffix="unit"
                :color="dense ? 'white' : 'darkGrey'"
                borderless
                @update:model-value="handleQuantityChange"
            />
            <!--<div class="text-center q-px-sm full-height row items-center justify-center" :class="[!dense ? 'col col-grow col-auto' : '']">
                {{ displayedQuantity }} {{ unit }}
            </div>-->
            <QBtn
                class="quantity-stepper-button"
                :flat="dense" 
                :size="dense ? 'sm' : '18px'"
                icon="add"
                :color="dense ? 'transparent' : 'accent'"
                :text-color="dense ? 'accent' : 'black'"
                @click="handleQuantityChange(quantity + increment)"
                :disabled="quantity >= maxQuantity || disabled"
            />
        </div>
    </div>
</template>
<style lang="scss">
    .quantity-stepper {
        border-radius: 8px; 
        overflow: hidden;
        height: 48px;
        
        .q-btn.quantity-stepper-button {
            border-radius: 0;
            padding: 0 !important;
            height: 100%;
            touch-action: manipulation;
    
            .q-btn__content {
                padding: 12px;
    
                .q-icon {
                    font-size: 24px;
                }
            }
        }

        .quantity-input {
            border: none !important;
            border-radius: 0;
            padding: 0 16px !important;
            width: 100%;

            &.dense {
                .q-field__native, .q-field__suffix {
                    color: white var(--q-white);
                }
            }
            
        }

        &.dense .q-btn__content {
            padding: 0 !important;
        }

        &.disabled {
            opacity: 0.3;
            cursor: not-allowed;
        }
    }
</style> 