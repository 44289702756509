<script>
import { mapStores } from 'pinia'
import { useUserStore } from '@/stores/user.js'

export default {
    data() {
        return {
            privacyPolicyAgreed: false,
            form: {
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                street: '',
                zip: '',
                city: '',
                country: '',
            },
            rules: {
                name: [
                    v => !!v || 'Name ist erforderlich',
                    v => (v && v.length >= 2) || 'Name muss mindestens 3 Zeichen lang sein',
                ],
                email: [
                    v => !!v || 'E-Mail ist erforderlich',
                    v => /.+@.+\..+/.test(v) || 'E-Mail muss gültig sein',
                ],
                phone: [
                    v => !!v || 'Telefonnummer ist erforderlich',
                    v => (v && v.length >= 10) || 'Telefonnummer muss mindestens 10 Zeichen lang sein',
                ],
                street: [
                    v => !!v || 'Straße ist erforderlich',
                    v => (v && v.length >= 3) || 'Straße muss mindestens 3 Zeichen lang sein',
                ],
                zipCode: [
                    v => !!v || 'PLZ ist erforderlich',
                    v => (v && v.length >= 4) || 'PLZ muss mindestens 4 Zeichen lang sein',
                ],
                city: [
                    v => !!v || 'Ort ist erforderlich',
                    v => (v && v.length >= 3) || 'Ort muss mindestens 3 Zeichen lang sein',
                ],
                country: [
                    v => !!v || 'Land ist erforderlich'
                ],
            },
            countries: [
                { label: 'Österreich', value: 'AT' },
                { label: 'Deutschland', value: 'DE' },
                { label: 'Schweiz', value: 'CH' },
            ],
            showPassword: false,
        }
    },

    computed: {
        ...mapStores(useUserStore),

        formValid() {
            return this.$refs.form.hasError === false && this.privacyPolicyAgreed === true
        }
    },

    emits: ['validated'],

    mounted() {
        if (this.userStore.isAuthenticated) {
            const {firstName, lastName, email, phone, street, zip, city, country} = this.userStore.wpUser.meta.billing
            this.form = {
                ...this.form,
                firstName,
                lastName,
                email,
                phone,
                street,
                zip,
                city,
                country
            }

            if (!email) {
                this.form.email = this.userStore.user.email
            }

            if (!firstName) {
                this.form.firstName = this.userStore.user.given_name
            }

            if (!lastName) {
                this.form.lastName = this.userStore.user.family_name
            }
            
            this.$refs.form.validate()
        }
    },

    methods: {
        validate() {
            this.$refs.form.validate().then(valid => {
                this.$emit('validated', valid && this.privacyPolicyAgreed)
            })

        }
    }
}

</script>
<template>
    <div>
        <QForm
            class="q-gutter-lg"
            ref="form"

        >
            <QInput
                v-model="form.firstName"
                label="Vorname"
                :rules="rules.name"
                lazy-rules
                @change="validate()"
            />

            <QInput
                v-model="form.lastName"
                label="Nachname"
                :rules="rules.name"
                lazy-rules
                @change="validate()"
            />

            <QInput
                v-model="form.email"
                label="E-mail Adresse"
                :rules="rules.email"
                lazy-rules
                @change="validate()"
            />

            <QInput
                v-model="form.phone"
                label="Telefonnummer"
                :rules="rules.phone"
                lazy-rules
                @change="validate()"
            />

            <QInput
                v-model="form.street"
                label="Straße Nr. / Stiege / ..."
                :rules="rules.street"
                lazy-rules
                @change="validate()"
            />

            <QInput
                v-model="form.zip"
                label="PLZ"
                :rules="rules.zipCode"
                lazy-rules
                @change="validate()"
            />

            <QInput
                v-model="form.city"
                label="Ort"
                :rules="rules.city"
                lazy-rules
                @change="validate()"
            />

            <QSelect
                v-model="form.country"
                :options="countries"
                label="Land"
                :rules="rules.country"
                color="white"
                text-color="darkGrey"
                @change="validate()"
            />

            <QCheckbox 
                v-model="privacyPolicyAgreed" 
                label="Ich habe die Datenschutzbestimmungen gelesen und akzeptiere diese."
                @click="validate()"
            />
        </QForm>

    </div>
</template>