//import './assets/main.css'
import 'quasar/dist/quasar.css'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/material-symbols-rounded/material-symbols-rounded.css'
import '@quasar/extras/fontawesome-v6/fontawesome-v6.css'
import '@wordpress/block-library/build-style/common.css'
import "@wordpress/block-library/build-style/style.css"
import "@wordpress/block-library/build-style/theme.css"

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { Quasar, Notify, Dialog, Meta, AddressbarColor } from 'quasar'
import { createAuth0 }  from '@auth0/auth0-vue'
//import iconSet from 'quasar/icon-set/material-icons'
import iconSet from 'quasar/icon-set/material-symbols-rounded'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import VueCookies from 'vue-cookies'
import * as Sentry from "@sentry/vue"

import router, { base } from './router.js'
import App from './App.vue'
import ActionItem from '@/components/Globals/ActionItem.vue'
import InfoBox from '@/components/Globals/InfoBox.vue'

const store = createPinia()
const app = createApp(App)

Sentry.init({
    app,
    dsn: "https://c5e4d0686caa6b9339a9a98706b4fc0f@o4507202625536000.ingest.de.sentry.io/4507202629533776",
    /*beforeSend(event) {
        // Check if it is an exception, and if so, show the report dialog
      if (event.exception && event.event_id) {
        Sentry.showReportDialog({ eventId: event.event_id });
      }
      return event;
    },*/ // Deactivate reporting dialog
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      enabled: import.meta.env.MODE !== 'development',
})

app.use(store).use(router).use(Quasar, { 
    config: {
        dark: true,
        iconSet: iconSet,
        cssAddon: true,
        brand: {
            primary: '#08231C', // = Grün 6
            secondary: '#0C3A2E', // = Grün 5
            tertiary: '#095c25', // = Grün 4
            accent: '#ADDD4B', // = Grün 3
            accentLight: '#d4ed9f', // = Grün 2
            accentPale: '#eeffca', // = Grün 1
            grey: '#5a5a5a',
            darkGrey: '#232323',
            lightGrey: '#a8a8a8',
            white: '#f3f3f3',
        }
    },
    plugins: {
        Notify, 
        Dialog,
        Meta,
        AddressbarColor
    }
}).use(VueGoogleMaps, {
    load: {
        key: import.meta.env.VITE_MAPS_API_KEY,
        language: 'de',
    },
}).use(VueCookies, { expires: '7d'}).use(createAuth0({
    domain: 'dev-nwl4nk7ckzzjqegr.eu.auth0.com',
    clientId: 'cZBAlfKQdeWcpW8IA8P9RwRRUomBNUti',
    useRefreshTokens: true,
    cacheLocation: 'localstorage',
    authorizationParams: {
        redirect_uri: window.location.origin + base,
        audience: 'http://regio-markt.local/wp-json/'
    }
}))

app.component('ActionItem', ActionItem)
app.component('InfoBox', InfoBox)

app.mount('#app')