<script>
import ProductCard from './ProductCard.vue';
import ProductFilter from './ProductFilter.vue';

import { mapStores } from 'pinia'
import { useUserStore } from '@/stores/user'
import { useProductStore } from '@/stores/product'

export default {
    data() {
        return {
            loading: false,
        }
    },

    components: {
        ProductCard,
        ProductFilter
    },

    mounted() {
        this.productStore.resetPagination()
    },

    watch: {
        // TODO: Move this to pickup point
        'userStore.pickupPoint' : { handler(oldValue, newValue) {
            if (oldValue !== newValue) {
                this.productStore.fetchProducts()
            } 
        }},
    },

    computed: {
        ...mapStores(useUserStore, useProductStore),

        categoryName() {
            return this.categories.length ? this.categories.find(category => category.id === this.categoryId).name : 'Alle Produkte';
        },
    },

    methods: {

        async fetchProducts(conservative = false) {
            this.productStore.fetchProducts(conservative)
        },

        async refreshProducts(done) {
            await this.productStore.fetchProducts()
            done()
        },

        setPage(page) {
            this.productStore.setPage(page)
            if (!this.productStore.isPageLoaded(page)) {
                this.productStore.fetchProducts(true).then(() => {
                    this.productStore.setPage(page)
                })
            }
        }
    }
}
</script>

<template>
    <div class="container">
        <ProductFilter />
        <QPullToRefresh @refresh="refreshProducts">
            <div class="q-px-md q-px-md-lg">
                <Transition 
                    enter-active-class="animate__animated animate__fadeInLeft animate__faster animate__delay-1s" 
                    leave-active-class="animate__animated animate__faster animate__fadeOutRight"
                >
            
                    <div v-if="!productStore.loading && this.productStore.products.length === 0" class="q-pa-md text-h6 text-center">
                        Keine Produkte gefunden
                    </div>

                    <div v-else-if="!productStore.loading" class="product-list row justify-center items-stretch">
                        <product-card 
                            :product-item-init="product" 
                            :id="product.id"
                            v-for="product in this.productStore.products"
                            :key="product.id"
                            class="col-12 col-lg-3 col-md-4 col-sm-6"
                        />
                    </div>

                    <div v-else class="product-list-skeleton row justify-center">
                        <div class="q-pa-md col-12 col-lg-3 col-md-4 col-sm-6" v-for="i in productStore.pagination.perPage" :key="i">
                            <q-card bordered style="max-width: 400px;" class="bg-primary">
                                <q-responsive :ratio="16/9">
                                    <q-skeleton style="width: 100%; height: 100%;" />
                                </q-responsive>
                                <q-card-section>
                                    <q-skeleton style="width: 100%; height: 30px;" />
                                </q-card-section>
                                <q-card-section>
                                    <q-skeleton style="width: 100%; height: 100px;" />
                                </q-card-section>
                                <q-card-actions align="center" class="q-pa-md">
                                    <q-skeleton type="QBtn" />
                                </q-card-actions>
                            </q-card>
                        </div>
                    </div>
                </Transition>

            </div>
        </QPullToRefresh>

        <div class="q-pa-lg flex flex-center">
            <q-pagination
                color="white"
                active-design="outline"
                v-if="productStore.showPagination && !productStore.loading"
                
                @update:model-value="this.setPage"
                :model-value="productStore.pagination.page"
                :max="productStore.maxPages"
                max-pages="4"
                direction-links
                boundary-numbers
            ></q-pagination>
        </div>
    </div>
</template>