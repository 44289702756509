<script>
import axios from 'axios'

export default {
    data() {
        return {
            page: null
        }
    },

    emits: ['content-loading', 'content-loaded'],

    async created() {
        this.$emit('content-loading')
        this.fetchPage()
    },

    props: {
        slug: {
            type: String,
            required: true
        },

        containerized: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        fetchPage() {
            axios.get(`${import.meta.env.VITE_API_URL}/wp-json/wp/v2/pages?slug=${this.slug}`)
            .then(response => {
                this.page = response.data[0]
                console.log("Fetched page:", this.page)
                this.$nextTick(() => this.renderContentLinks() )
            })
            .catch(error => {
                console.error("Error fetching page content:", error)
                this.$q.notify({
                    color: 'negative',
                    icon: 'report_problem',
                    message: 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.',
                    position: 'top',
                    timeout: 3000
                });
            }).finally(() => {
                this.$emit('content-loaded')
            })
        
        },

        renderContentLinks() {
            const content = this.$refs.cmsPage
            const links = content.getElementsByTagName('a')
            console.log(links.length)

            for (let i = 0; i < links.length; i++) {
                const link = links[i]
                const href = link.getAttribute('href')

                if (!href)
                    continue

                const isExternal = href.startsWith('http') || href.startsWith('https')

                if (!isExternal) {
                    link.addEventListener('click', (e) => {
                        e.preventDefault()
                        this.$router.push(href)
                    })
                }
            }
        }
    }
}
</script>

<template>
    <div ref="cmsPage" :class="[ containerized ? 'container q-px-md q-py-lg' : '' ]" v-html="page?.content.rendered"></div>
</template>