<script>
import { mapStores } from 'pinia'
import { useUserStore } from '@/stores/user.js'
import { usePickupPointStore } from '@/stores/pickupPoint'

import { debounce } from 'quasar';

import axios from 'axios';

export default {
    data() {
        return {
            loading: false,
            searchString: '',
            suggestions: null,
            showAutocomplete: false,
            showLocation: true,
        }
    },

    emits: ['selected-place' ],

    mounted() {
        this.fetchPlacesAutocomplete = debounce(this.fetchPlacesAutocomplete, 500)
    },

    computed: {
        ...mapStores(useUserStore, usePickupPointStore),
        fieldValue() {
            return this.showLocation && this.userStore.location ? this.userStore.location.name : this.searchString
        }
    },

    methods: {
        fetchPlacesAutocomplete(input) {
            this.searchString = input
            this.showLocation = false

            if (input.length === 0)
                return

            this.loading = true
            axios.post('https://places.googleapis.com/v1/places:autocomplete', {
                input,
                includedPrimaryTypes: '(regions)',
                includedRegionCodes: ['at'],
                languageCode: 'de',
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Goog-Api-Key': import.meta.env.VITE_MAPS_API_KEY,
                }
            }).then((response) => {
                this.suggestions = response.data.suggestions
            }).finally(() => {
                this.loading = false
            })
        },

        async fetchPlaceDetails(placeId) {
            return axios.get(`https://places.googleapis.com/v1/places/${placeId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Goog-Api-Key': import.meta.env.VITE_MAPS_API_KEY,
                    'X-Goog-FieldMask': 'displayName,location'
                }
            })
        },

        async selectPlace(placeId) {
            let place = null
            
            if (placeId === 'current-location') {
                const coordinates = await this.userStore.getCoordinates()
                place = {
                    placeId: 'current-location',
                    name: 'aktueller Standort',
                    coordinates
                }
            } else {
                const placeDetails = await this.fetchPlaceDetails(placeId)

                place = {
                    placeId: placeId,
                    name: placeDetails.data.displayName.text,
                    coordinates: placeDetails.data.location
                }
            }
            
            this.showLocation = true
            this.showAutocomplete = false
            this.userStore.setLocation(place)
            this.$emit('selected-place', place)
        },

    }
}
</script>

<template>
    <div class="row justify-center q-py-md">
        <div class="relative-position full-width container-sm">
            <QInput
                bordered
                text-color="darkGrey"
                class="full-width"
                label="PLZ, Ort..."
                :model-value="fieldValue"
                :loading="loading"
                @focus="showAutocomplete = true"
                @blur="showAutocomplete = false"
                @update:model-value="fetchPlacesAutocomplete"
                @keyup.enter="selectPlace(suggestions[0].placePrediction.placeId)"
            >
                <template v-slot:prepend>
                    <QIcon name="search" />
                </template>

                <template v-slot:append v-if="!showAutocomplete">
                    <QIcon name="my_location" class="cursor-pointer" @click="selectPlace('current-location')" />
                </template>
            </QInput>
            <QExpansionItem v-model="showAutocomplete" header-class="hidden" class="absolute full-width z-top">
                <div class="search-autocomplete text-darkGrey">
                    <div class="search-pickup-points">
                        <div class="result-caption text-micro q-px-md q-py-sm bg-accentLight">Abholstationen</div>
                        <div class="row result-item" @click="selectPlace('current-location')">
                            <div class="col-8">aktueller Standort</div>
                            <div class="col-4 text-right">
                                <QIcon name="my_location" size="20px" color="tertiary" />
                            </div>
                        </div>
                        <div
                            v-for="place in suggestions"
                            class="result-item"
                            :key="place.placeId"
                            @click="selectPlace(place.placePrediction.placeId)"
                        >{{ place.placePrediction.structuredFormat.mainText.text }}</div>
                    </div>
                </div>
            </QExpansionItem>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .search-autocomplete {
        border-radius: 0 0 8px 8px;
        background-color: var(--q-white);
        margin-top: -8px;

        .result-item {
            padding: 8px 16px;
            cursor: pointer;

            &:not(:last-child) {
                border-bottom: 1px solid var(--q-accentLight);
            }
        }
    }
</style>