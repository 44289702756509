<script>
import { mapStores } from 'pinia';
import { useNavigationStore } from '@/stores/navigation';

export default {
    data() {
        return {
            version: import.meta.env.VITE_APP_VERSION
        }
    },

    computed: {
        ...mapStores(useNavigationStore)
    },
}
</script>

<template>
    <footer class="footer absolute-bottom q-px-md">
        <div class="row full-height">
            <div class="col-8 column justify-end">
                <div class="text-accent cursor-pointer" @click="navigationStore.openContactDialog()">Kontakt</div>
                <div class="q-mt-md"><RouterLink to="/imprint" class="text-accent">Impressum & Datenschutz</RouterLink></div>
            </div>
            <div class="col-4 column justify-end text-right">
                &copy; {{ new Date().getFullYear() }} RegioMarkt
                <div class="text-micro text-lightGrey">Version: {{ version }}</div>
            </div>
        </div>
    </footer>
</template>

<style lang="scss" scoped>
    .footer {
        background-image: url('@/assets/Footer.svg');
        background-size: cover;
        background-repeat: no-repeat;
        height: 212px;
        padding-top: 60px;
        padding-bottom: 60px;
    }
</style>