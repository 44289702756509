<script>
import { mapStores } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useNavigationStore } from '@/stores/navigation';

export default {
    computed: {
        ...mapStores(useUserStore, useNavigationStore),
        drawerWidth() {
            return this.$q.screen.width < 480 ? this.$q.screen.width : 400;
        }
    },

    methods: {
        handleClick(item) {
            this.navigationStore.closeMenu()
            if (item.click)
                item.click()
        }
    }
}
</script>

<template>
    <QDrawer class="bg-primary q-pa-md" side="right" v-model="navigationStore.menuOpened" :width="drawerWidth" :breakpoint="500" bordered>
        <QBtn flat round icon="close" @click="navigationStore.closeMenu()" class="q-ma-md" />
        <QList>
            <QItem class="text-accent cursor-pointer" v-ripple v-for="item in navigationStore.menuItems" :key="item.link" :to="item.link">
                <QItemSection :class="item.link == $route.path ? 'text-bold text-accentLight' : ''" @click="handleClick(item)">{{ item.title }}</QItemSection>
            </QItem>
            <QSeparator color="tertiary" class="q-my-md" />
            <QItem class="text-accent cursor-pointer" v-ripple v-for="item in navigationStore.userMenuItems" :key="item.link" :to="item.link">
                <QItemSection @click="handleClick(item)">{{ item.title }}</QItemSection>
            </QItem>
        </QList>
    </QDrawer>
</template>

<style lang="scss">
    .q-drawer {
        &.q-drawer--right.q-drawer--bordered {
            border-left: 2px solid var(--q-tertiary);
        }
    }
</style>