<script>
import { mapStores } from 'pinia'
import { useOrderStore } from '@/stores/order'
import { usePickupPointStore } from '@/stores/pickupPoint'
import { useNavigationStore } from '@/stores/navigation'
import { useUserStore } from '@/stores/user'

import CartItem from '@/components/Checkout/CartItem.vue'
import CartTotals from '@/components/Checkout/CartTotals.vue'
import PickupPointInfo from '@/components/PickupPoint/PickupPointInfo.vue'

export default {
    data() {
        return {
            loading: false,
        }
    },

    computed: {
        ...mapStores(useOrderStore, usePickupPointStore, useNavigationStore, useUserStore),

        orderId() {
            return this.$route.params.orderId
        },

        orderItem() {
            return this.orderStore.getOrder(this.orderId)
        },

        orderItemDate() {
            const orderDate = this.orderItem.date;
            const dateParts = orderDate.split(".");
            const orderTime = this.orderItem.time;
            const timeParts = orderTime.split(":")
            return new Date(dateParts[2], dateParts[1] - 1, dateParts[0], timeParts[0], timeParts[1], timeParts[2]);
        },

        pickupPoint() {
            return this.pickupPointStore.getPickupPoint(this.orderItem.pickupPointId)
        },

        isCancelable() {
            const deadline = this.pickupPointStore.nextOrderDeadline(this.orderItem.pickupPointId, this.orderItemDate)
            return (this.orderItem.status === 'pending' || this.orderItem.status === 'processing') && this.userStore.time <= deadline
        }
    },

    components: {
        CartItem,
        CartTotals,
        PickupPointInfo
    },

    methods: {
        cancelOrder(orderId) {
            this.loading = 'cancelOrder'
            this.orderStore.cancelOrder(orderId)
                .then(() => {
                    this.$q.notify({
                        message: 'Bestellung wurde storniert',
                        color: 'positive',
                        position: 'top',
                        timeout: 2000
                    })
                    this.$router.push('/profile/orders')
                })
                .finally(() => {
                    this.loading = false
                }
            )
        }
    }
}
</script>

<template>
    <div v-if="orderItem" class="q-py-lg">
        <div class="row align-center">
            <div class="col-shrink column justify-center">
                <QBtn icon="arrow_back_ios" to="/profile/orders" size="24px" flat color="accent" />
            </div>
            <div class="col-grow text-center">
                <div class="text-h2">{{ orderItem.date }}</div>
                <div class="text-center">Bestellnummer: {{ orderItem.id }}</div>
            </div>
        </div>

        <div class="q-py-lg">
            <CartItem v-for="item in orderItem.items" :key="item.id" :cart-item="item" disable-actions />
        </div>
        <CartTotals :totals="orderItem.totals" :fees="orderItem.fees" hide-add-coupon />

        <div v-if="orderItem.status === 'pending' || orderItem.status === 'processing'" class="q-py-md text-center">
            <div class="q-mb-md text-h4">Abholung</div>
            <PickupPointInfo 
                v-if="orderItem.pickupPointId" 
                :pickupPoint="pickupPointStore.getPickupPoint(orderItem.pickupPointId)" 
                :orderDate="orderItemDate"
                stretched="md" 
            />
            <QBtn 
                v-if="isCancelable"
                label="Stornieren"
                color="accent"
                text-color="accent"
                class="q-mx-auto q-my-xl"
                outline
                :loading="loading === 'cancelOrder'"
                @click="cancelOrder(orderId)"
            />
        </div>
        <div v-else class="q-py-md text-center">
            <div class="q-mb-md text-h4">Status</div>

            <div class="row justify-center q-pa-md bg-secondary" style="margin: 0 -16px;">
                <QIcon v-if="orderItem.status === 'completed'" name="check" size="34px" color="accent" />
                <QIcon v-else name="sym_r_cancel" size="34px" color="negative" />
                <div class="text-h4 text-center q-px-md">{{ orderStore.getStatusLabel(orderItem.status) }}</div>
            </div>

        </div>
        <div class="text-center">
            <QBtn v-if="!isCancelable" label="Problem melden" color="accent" text-color="accent" class="q-mx-auto q-my-xl" outline @click="navigationStore.openSupportDialog()" />
        </div>
    </div>
    <div class="q-pa-lg text-center" v-else>
        <QSpinner size="50px" class="q-mx-auto" />
    </div>
</template>