<script>
export default {
    props: {
        message: String
    }
}
</script>

<template>
    <div class="preloader fit fixed z-max column justify-center text-center">
        <img src="@/assets/animations/loading.gif" width="215px" class="q-my-lg q-mx-auto" />
        <div class="text-bold">{{ message }}...</div>
    </div>
</template>

<style scoped lang="scss">
    @use '@/sass/mixins';

    .preloader {
        @include mixins.background-gradient;
    }
</style>