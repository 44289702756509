<!-- eslint-disable vue/multi-word-component-names -->
<script>
import ProductList from '@/components/ProductList/ProductList.vue';
import PickupPoint from '@/components/PickupPoint/PickupPoint.vue';
import PickupPointInfo from '@/components/PickupPoint/PickupPointInfo.vue';

import { mapStores } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useProductStore } from '@/stores/product';
import { useCartStore } from '@/stores/cart';
import { usePickupPointStore } from '@/stores/pickupPoint';
import { useNavigationStore } from '@/stores/navigation';

export default {
    components: {
        ProductList,
        PickupPoint,
        PickupPointInfo
    },

    mounted() {
        this.productStore.fetchProducts();
    },

    computed: {
        ...mapStores(useUserStore, useProductStore, useCartStore, usePickupPointStore, useNavigationStore)
    }
}
</script>

<template>
    <div>
        <PickupPointInfo 
            class="cursor-pointer"
            changable
            shortAddress
            showDeadline
            :pickupPoint="userStore.pickupPoint"
            @click="pickupPointStore.showSelector = !pickupPointStore.showSelector" 
        />
        <PickupPoint />
        <div class="text-center q-py-lg">
            <h2 class="q-ma-none">Wähle deine Produkte:</h2>
        </div>
        <ProductList />
        <div class="q-my-lg text-center container-sm">
            <div class="text-h4">Lerne unsere Produzenten kennen!</div>
            <QSeparator width="150px" class="dashed q-my-md q-mx-auto" />
            <div class="">
                Alle Produkte die Du hier findest, legen den kleinstmöglichen Weg zurück und stammen von ausgewählten Produzenten aus Deiner Region.
            </div>
            <QBtn label="Zu den Produzenten" class="q-mt-xl q-mx-auto" color="accent" text-color="black" @click="$router.push('/producers')" />
        </div>
    </div>
</template>