<script>
import { mapStores } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useCartStore } from '@/stores/cart';
import { useNavigationStore } from '@/stores/navigation';

export default {
    data() {
        return {
            logoUrl: '',
        }
    },

    mounted() {

    },

    computed: {
        ...mapStores(useUserStore, useCartStore, useNavigationStore)
    },

    methods: {
        
    }
}
</script>

<template>
    <QHeader reveal class="bg-primary q-pa-md" ref="appHeader">
        <QToolbar>
            <QToolbarTitle class="cursor-pointer" @click="this.$router.push('/')">
                <img v-if="$q.platform.is.mobile" src="@/assets/logo.png" style="height:50px; width:50px;" />
                <img v-else src="@/assets/full-logo.svg" style="width:150px" />
            </QToolbarTitle>
            <div class="q-gutter-sm q-gutter-md-md">
                <QBtn flat round to="/profile">
                    <QAvatar size="40px" @click="this.$router.push('/login')" class="cursor-pointer" v-if="userStore.isAuthenticated">
                        <img :src="userStore.user.picture" referrerpolicy="no-referrer" />
                    </QAvatar>
                    <QIcon name="person" color="accent" v-else />
                </QBtn>
                
                <!--<QBtn flat round icon="person" to="/login" color="accent" v-else />-->
                <QBtn flat round icon="sym_r_shopping_basket" color="accent" v-if="!cartStore.isEmpty" @click="this.$router.push('/checkout')">
                    <QBadge color="red" class="vertical-bottom" rounded floating>{{ cartStore.itemCount }}</QBadge>
                </QBtn>
                <QBtn flat round icon="menu" color="accent" @click="navigationStore.openMenu()" />
            </div>
        </QToolbar>
    </QHeader>
</template>