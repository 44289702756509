import { defineStore } from 'pinia'
import { useUserStore } from './user'

import { Dialog } from 'quasar'

import ContactDialog from '@/dialogs/ContactDialog.vue'
import FeedbackDialog from '@/dialogs/FeedbackDialog.vue'
import SupportDialog from '@/dialogs/SupportDialog.vue'

export const useNavigationStore = defineStore('navigation', {
    state: () => {
        return {
            menuOpened: false,
        }
    },

    getters: {
        menuItems() {
            return [
                { title: 'Produkte', link: '/' },
                { title: 'Produzenten', link: '/producers' },
                { title: 'RegioMarkt', link: '/about' }
            ]
        },

        userMenuItems() {
            return [
                { title: 'Mein Konto', link: '/profile' },
                /*{ title: 'Abholstation ändern', click: () => {
                    console.log(usePickupPointStore())
                    usePickupPointStore.showSelector = true
                } },*/
                { title: 'Kontakt', click: () => {
                    this.openContactDialog()
                    this.closeMenu()
                }},
                { title: 'Abmelden', click: () => {
                    useUserStore().logout()
                } }
            ]
        }
    },

    actions: {
        openMenu() {
            this.menuOpened = true
        },

        closeMenu() {
            this.menuOpened = false
        },

        openContactDialog() {
            Dialog.create({
                component: ContactDialog,
                componentProps: {
                    cf7Id: 244
                }
            })
        },

        openFeedbackDialog() {
            Dialog.create({
                component: FeedbackDialog,
                componentProps: {
                    cf7Id: 245
                }
            })
        },

        openSupportDialog() {
            Dialog.create({
                component: SupportDialog,
                componentProps: {
                    cf7Id: 246
                }
            })
        }
    }
})